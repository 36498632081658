import { Link } from "gatsby";
import { btn, btnPrimary } from '../ds/button.module.css';
import { BlackArrowRight, WhiteArrowRight } from "../ds/Shapes";
import { StaticImage } from "gatsby-plugin-image";
import useWindowDimensions from "../utils/useWindowDimensions";
import Header from "../components/Header";
import Layout from "../components/Layout";
import MagicWithUs from "../components/inner-page/MagicWithUs";
import Feebacks from "../components/landing-page/Feebacks";
import StarRating from '../images/svgs/star_yellow.svg';
import G2Icon from '../images/svgs/g2_red.svg';

export default function Ripple() {
  const { width } = useWindowDimensions();
  const isMobileUp = width >= 1080;
  const containerWidth = isMobileUp ? '1080px' : '100%';
  const heroBgHeight = isMobileUp ? '110vh' : '115vh';
    return (
        <Layout title="Best event networking app" description="Best event networking app">
    <div className=''>
        <div className="" style={{ backgroundColor: '#F2EFFF' }}>
            <Header />
        </div>

        <section className="pt-10" style={{ backgroundColor: '#F2EFFF' }}>
        <div className="px-5 md:px-0 mx-auto" style={{ width: containerWidth }}>
            <div className="md:flex">
                <div className="md:w-1/2 flex flex-col justify-center text-left">
                    <h1 className="text-3xl md:text-5xl font-bold md:leading-snug mb-8 text-black"><span style={{ color: '#724FF4' }}>Ripple</span> - Event Networking</h1>
                    <p>Go beyond traditional networking with an engaging <br />attendee experience that empowers <br />meaningful connections.</p>
                    <Link to="/request-a-demo/" className={`${btn} ${btnPrimary} text-xs mt-5 mb-20 md:mb-0 relative w-max group`}>
                    <span className='mr-4 ml-2'>Get a demo</span>
                    <WhiteArrowRight className='transition duration-300 ease-in-out group-hover:translate-x-2 mr-2' />
                    </Link>
                </div>
                <div className="md:w-1/2 md:ml-0">
                    <StaticImage src="../images/ripple_hero.png" alt="" />
                </div>
            </div>
        </div>
        </section>  
        <section className="md:pt-10">
        <div className="px-5 md:px-0 mx-auto" style={{ width: containerWidth }}>
            <div className="md:flex mt-20">
                <div className="md:w-1/2 md:pr-10">
                    <StaticImage src="../images/feature1.png" alt="" />
                </div>
                <div className="md:w-1/2 flex flex-col justify-center text-left mt-10 md:mt-0 md:pl-10">
                    <h1 className="text-3xl md:text-4xl font-bold md:leading-snug mb-8 text-black">Connect via faces</h1>
                    <p>Simply tap on attendee faces in photo galleries to access their key information.</p>
                    <Link to="/request-a-demo/" className={`${btn} ${btnPrimary} text-xs mt-5 mb-20 md:mb-0 relative w-max group`}>
                    <span className='mr-4 ml-2'>Get a demo</span>
                    <WhiteArrowRight className='transition duration-300 ease-in-out group-hover:translate-x-2 mr-2' />
                    </Link>
                </div>
            </div>
            <div className="flex flex-col md:flex-row md:my-20 flex-col-reverse">
                <div className="md:w-1/2 flex flex-col justify-center text-left md:pr-10 mt-10 md:mt-0">
                    <h1 className="text-3xl md:text-4xl font-bold md:leading-snug mb-8 text-black">Integrated Social Networking</h1>
                    <p>Integrate multiple social profiles to unlock better networking opportunities.</p>
                    <Link to="/request-a-demo/" className={`${btn} ${btnPrimary} text-xs mt-5 mb-20 md:mb-0 relative w-max group`}>
                    <span className='mr-4 ml-2'>Get a demo</span>
                    <WhiteArrowRight className='transition duration-300 ease-in-out group-hover:translate-x-2 mr-2' />
                    </Link>
                </div>
                <div className="md:w-1/2 md:pl-10">
                    <StaticImage src="../images/feature2.png" alt="" />
                </div>
            </div>
            <div className="md:flex mb-20">
                <div className="md:w-1/2 md:pr-10">
                    <StaticImage src="../images/feature3.png" alt="" />
                </div>
                <div className="md:w-1/2 flex flex-col justify-center text-left md:pl-10 mt-10 md:mt-0">
                    <h1 className="text-3xl md:text-4xl font-bold md:leading-snug mb-8 text-black">Engaging Value Proposition</h1>
                    <p>Stand out with a value proposition that increases engagement and ROI.</p>
                    <Link to="/request-a-demo/" className={`${btn} ${btnPrimary} text-xs mt-5 mb-20 md:mb-0 relative w-max group`}>
                    <span className='mr-4 ml-2'>Get a demo</span>
                    <WhiteArrowRight className='transition duration-300 ease-in-out group-hover:translate-x-2 mr-2' />
                    </Link>
                </div>
            </div>
        </div>
        </section>
        <section className="h-screen mb-40 flex flex-col justify-center" style={{ backgroundColor: '#7250F4' }}>
            <h1 className="text-white font-semibold text-4xl text-center">With <span style={{ color: '#60F2C2' }}>Premagic</span>, your event is <br />in good hands.</h1>
        <div className='flex justify-center flex-wrap md:gap-4 mt-20'>
            <StaticImage
                className='w-16 md:w-36 md:mx-5'
                placeholder="blurred"
                src="../images/badges/1.png"
                alt="logo"

                />
            <StaticImage
                className='w-16 md:w-36 md:mx-5'
                placeholder="blurred"
                src="../images/badges/2.png"
                alt="logo"

                />
            <StaticImage
                className='w-16 md:w-36 md:mx-5'
                placeholder="blurred"
                src="../images/badges/3.png"
                alt="logo"

                />
            <StaticImage
                className='w-16 md:w-36 md:mx-5'
                placeholder="blurred"
                src="../images/badges/4.png"
                alt="logo"

                />
        </div>
        <div className="flex justify-center mt-20">
        <StaticImage
                className='w-16 md:w-36 mx-5'
                placeholder="blurred"
                src="../images/tie.png"
                alt="logo"

                />
        <StaticImage
                className='w-16 md:w-32 mx-5'
                placeholder="blurred"
                src="../images/wow.png"
                alt="logo"

                />
        </div>
        </section>
        <Feebacks />
        <MagicWithUs />
    </div>      
    </Layout>
    )
}