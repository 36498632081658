
import React from 'react';
import Layout from '../../../components/Layout';
import Header from '../../../components/Header';
import CaseStudy, { CASE_STUDY_TYPES } from '../../../components/case-study/CaseStudy';
import ThumbnailImage from '../../../images/thumbnails/saasboomi_thumb.png';

export function Index() {
  return (
    <Layout 
    title="Find how SaaSBOOMi achieved real-time engagement with Premagic"
    description="Find how SaaSBOOMi achieved real-time engagement with Premagic"
    metaImage={ThumbnailImage}>
      <Header />
      <CaseStudy type={CASE_STUDY_TYPES.SAASBOOMI} />
    </Layout>
  );
}

export default Index;