import { Link } from "gatsby";
import { BlackArrowRight } from "../ds/Shapes";


export function Dropdown() {

    return (
        <div className={`shadow-2xl border border-slate-100 hidden group-hover:block absolute top-20 mt-2 -left-40 -right-40 bg-white z-10 overflow-hidden transition duration-300 ease-in-out`}>
          <div className="container mx-auto">
              <div className='p-8'>
                <div className="flex space-between -ml-4">
                  <div className="w-1/3">
                    <Link className='text-sm text-black rounded-xl p-4 block transition delay-10 duration-200 ease-in-out hover:bg-rose-50' to="/case-study/umagine/">
                      <h4 className="text-base text-black font-semibold mb-2">UmagineChennai</h4>
                      <p className="text-xs text-zinc-500 h-8 pr-20 font-normal">Delivering an experience that goes beyond just networking.</p>
                    </Link>
                    <Link className='text-sm text-black rounded-xl p-4 block transition delay-10 duration-200 ease-in-out hover:bg-rose-50' to="/case-study/saasboomi/">
                      <h4 className="text-base text-black font-semibold mb-2">SaaSBOOMi Annual</h4>
                      <p className="text-xs text-zinc-500 h-8 pr-20 font-normal">Tackling the hurdle of event photos management</p>
                    </Link>
                    <Link className='text-sm text-black rounded-xl p-4 block transition delay-10 duration-200 ease-in-out hover:bg-rose-50' to="/case-study/nasscom/">
                      <h4 className="text-base text-black font-semibold mb-2">Nasscom GCC Conclave</h4>
                      <p className="text-xs text-zinc-500 h-8 pr-20 font-normal">Empowering sponsor-audience engagement </p>
                    </Link>
                    <Link className='text-sm text-black rounded-xl p-4 block transition delay-10 duration-200 ease-in-out hover:bg-rose-50' to="/case-study/wowawards/">
                      <h4 className="text-base text-black font-semibold mb-2">Wow Awards</h4>
                      <p className="text-xs text-zinc-500 h-8 pr-20 font-normal">Leveraging the power of instant delivery to create an impact</p>
                    </Link>
                  </div>
                  <div className="w-1/3">
                    <Link className='text-sm text-black rounded-xl p-4 block transition delay-10 duration-200 ease-in-out hover:bg-rose-50' to="/case-study/showsofindia/">
                      <h4 className="text-base text-black font-semibold mb-2">Shows of India</h4>
                      <p className="text-xs text-zinc-500 h-8 pr-20 font-normal">Merging creativity and technology to craft an impactful event experience</p>
                    </Link>
                    <Link className='text-sm text-black rounded-xl p-4 block transition delay-10 duration-200 ease-in-out hover:bg-rose-50' to="/case-study/asiaberlin/">
                      <h4 className="text-base text-black font-semibold mb-2">Asia Berlin</h4>
                      <p className="text-xs text-zinc-500 h-8 pr-20 font-normal">A streamlined and innovative event marketing solution for ABS2023</p>
                    </Link>
                    <Link className='text-sm text-black rounded-xl p-4 block transition delay-10 duration-200 ease-in-out hover:bg-rose-50' to="/case-study/aioc/">
                      <h4 className="text-base text-black font-semibold mb-2">AIOC</h4>
                      <p className="text-xs text-zinc-500 h-8 pr-20 font-normal">Resolving DAM Challenges for an organisation of ophthalmologists</p>
                    </Link>
                    <Link className='text-sm text-black rounded-xl p-4 block transition delay-10 duration-200 ease-in-out hover:bg-rose-50' to="/case-study/real-estate-development-summit/">
                      <h4 className="text-base text-black font-semibold mb-2">Real Estate Development Summit</h4>
                      <p className="text-xs text-zinc-500 h-8 pr-20 font-normal">Simplifying interactions for enhanced UGC by real estate professionals.</p>
                    </Link>
                  </div>
                  <div className="w-1/3">
                    <Link className='text-sm text-black rounded-xl p-4 block transition delay-10 duration-200 ease-in-out hover:bg-rose-50' to="/case-study/mercedes-benz/">
                      <h4 className="text-base text-black font-semibold mb-2">Coastal Star - Mercedes Benz</h4>
                      <p className="text-xs text-zinc-500 h-8 pr-20 font-normal">Elevating event standards to align with brand values.</p>
                    </Link>

                    <Link className='text-sm text-black rounded-xl p-4 block transition delay-10 duration-200 ease-in-out hover:bg-rose-50' to="/case-study/jsconf/">
                      <h4 className="text-base text-black font-semibold mb-2">JSConf India</h4>
                      <p className="text-xs text-zinc-500 h-8 pr-20 font-normal">Creating connections through digital tools for the tech community.</p>
                    </Link>
                    <Link className='text-sm text-black rounded-xl p-4 block transition delay-10 duration-200 ease-in-out hover:bg-rose-50' to="/case-study/yuva-galam/">
                      <h4 className="text-base text-black font-semibold mb-2">Yuva Galam Padayatra</h4>
                      <p className="text-xs text-zinc-500 h-8 pr-20 font-normal">Learn how Nara Lokesh's Yuvagalam Padayatra used Premagic to drive TDP's success.</p>
                    </Link>
                    <Link className='text-sm text-black rounded-xl p-4 block transition delay-10 duration-200 ease-in-out hover:bg-rose-50' to="/case-study/gtech/">
                      <h4 className="text-base text-black font-semibold mb-2">GTECH Kerala Marathon</h4>
                      <p className="text-xs text-zinc-500 h-8 pr-20 font-normal">Discover How Premagic Powered GTECH Kerala Marathon 2024 with AI-Driven Photo Distribution.</p>
                    </Link>
                  </div>
                </div>
                <Link to="/case-study/" className='flex items-center py-2 block mt-3'>
                    <span className='mr-2 text-sm font-semibold text-black'>View all</span>
                    <BlackArrowRight className='transition delay-100 duration-300 ease-in-out' />
                </Link>
              </div>
            </div>
        </div>
    )
}