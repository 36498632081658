import React from 'react';
import Header from '../components/Header';
import Hero from '../components/landing-page/Hero';
import Clients from '../components/landing-page/Clients';
import Layout from '../components/Layout';

import '../index.css';
import Features from '../components/landing-page/Features';
import { Ecosystem } from '../components/landing-page/Ecosystem';
import MagicWithUs from '../components/inner-page/MagicWithUs';
import Recommend from '../components/landing-page/Recommend';
import Feebacks from '../components/landing-page/Feebacks';
import KeyFeatures from '../components/landing-page/KeyFeatures';
import { PreToPostEvent } from '../components/landing-page/PreToPostEvent';

export function Index() {
  return (
    <Layout title="Boost your event marketing by 200%
    with our AI-driven strategies">
      <Header isWhiteLogo />
      <Hero />
      <Clients />
      <PreToPostEvent />
      {/* <Ecosystem /> */}
      <Features />
      <KeyFeatures />
      <Recommend />
      <Feebacks />
      <MagicWithUs />
    </Layout>
  );
}

export default Index;
