import { StaticImage } from 'gatsby-plugin-image';
import useWindowDimensions from '../../utils/useWindowDimensions';
import { SmilingFace, MultiStars, Rocket, SpeedoMeter, Box, OrangeTick } from '../../ds/Shapes';
import { useState } from 'react';
import { Link } from 'gatsby';
import {
  btn,
  btnSmall,
  btnDefault,
  btnPrimary,
} from '../../ds/button.module.css';
import { WhiteArrowRight } from '../../ds/Shapes';

export enum TAB_TYPES {
    CLICK = 'click',
    UPLOAD = 'upload',
    AUTO_GENERATE = 'auto_generate',
    DELIVER = 'deliver',
    SHARE = 'share'
  }

const KEY_FEATURES = {
    [TAB_TYPES.CLICK]: {
        tabTitle: 'Click',
        description: 'Your attendees register their details along with a selfie.',
        image: <StaticImage height={480} src='../../images/photo_dist_1.png' alt='' />,
        icon: (color) => <SmilingFace color={color} />
    },
    [TAB_TYPES.UPLOAD]: {
        tabTitle: 'Upload',
        description: 'The photographer uploads all event photos to Premagic.',
        image: <StaticImage height={480} src='../../images/photo_dist_2.png' alt='' />,
        icon: (color) => <Rocket color={color} />
    },
    [TAB_TYPES.AUTO_GENERATE]: {
        tabTitle: 'Auto-generate',
        description: 'AI identifies each attendee based on their selfies and generates a personalized gallery.',
        image: <StaticImage height={480} src='../../images/photo_dist_3.png' alt='' />,
        icon: (color) => <SpeedoMeter color={color} />
    },
    [TAB_TYPES.DELIVER]: {
        tabTitle: 'Deliver',
        description: `The gallery is delivered to the attendee via Whatsapp and email.`,
        image: <StaticImage height={480} src='../../images/photo_dist_4.png' alt='' />,
        icon: (color) => <Box color={color} />
    },
    [TAB_TYPES.SHARE]: {
        tabTitle: 'Share',
        description: `The attendee share their event photos on social media instantly.`,
        image: <StaticImage height={480} src='../../images/photo_dist_5.png' alt='' />,
        icon: (color) => <Box color={color} />
    },
}

export function HowItWorks() {
    const { width } = useWindowDimensions();
    const isMobileUp = width >= 1080;
    const containerWidth = isMobileUp ? '1080px' : '100%';
    const [activeTab, setActiveTab] = useState(TAB_TYPES.CLICK);

    return (
      <section className='mb-20' style={{ backgroundColor: '#E3F2ED' }}>
        <div className='mx-auto py-20 flex items-center md:w-[1080px]'>
            <div className='w-1/2 pr-28'>
            <h4 className="font-bold text-2xl mb-5" style={{ color: '#7251F4' }}>How it works</h4>
            <div className='bg-white rounded-xl p-5 overflow-scroll no-scrollbar mx-5 md:mx-0'>
            
                {
                    Object.keys(KEY_FEATURES).map(key => (
                        <div className='mr-2 md:mx-0 flex items-center p-4 rounded-xl bg-white cursor-pointer hover:bg-purple-50 transition duration-300 ease-in-out' style={{ backgroundColor: activeTab === key ? '#7251F4': '' }} onClick={() => setActiveTab(key)}>
                            <div className='scale-125 md:scale-100'>
                                {KEY_FEATURES[key].icon(activeTab === key ? '#FFFFFF' : '#121212')}
                            </div>
                            <p className={`ml-3 font-medium text-sm ${activeTab === key ? 'text-white': 'text-black'} `}>{KEY_FEATURES[key].tabTitle}</p>
                        </div>
                    ))
                }
            </div>
            </div>
            <div className='w-1/2 mt-14 px-10 md:px-0'>
                <div className='h-[480px] flex items-center'>
                    {KEY_FEATURES[activeTab].image}
                </div>
                <div className=''>
                    <h2 className='text-black text-3xl md:w-2/3 leading-relaxed font-bold mb-2 pr-20 leading-tight md:leading-tight relative z-20' style={{ color: '#7251F4' }}>{KEY_FEATURES[activeTab].tabTitle}</h2>
                    <p className='text-sm mb-5 md:w-2/3 leading-relaxed'>{KEY_FEATURES[activeTab].description}</p>
                </div>
            </div>
        </div>
      </section>
    );
  }
  
  export default HowItWorks;
