import { StaticImage } from 'gatsby-plugin-image';
import useWindowDimensions from '../../utils/useWindowDimensions';
import { SmilingFace, MultiStars, Rocket, SpeedoMeter, Box, OrangeTick } from '../../ds/Shapes';
import { useState } from 'react';
import { Link } from 'gatsby';
import {
  btn,
  btnSmall,
  btnDefault,
  btnPrimary,
} from '../../ds/button.module.css';
import { WhiteArrowRight } from '../../ds/Shapes';


export function Index() {

    return (
      <section className='mb-20'>
        <div className='mx-auto py-20 md:w-[1080px]'>
            <div className='md:flex items-center'>
                <div className='md:w-1/2'>
                    <StaticImage src='../../images/attendee_to_content_creator.png' alt='' />
                </div>
                <div className='md:w-1/2 pl-10 md:pl-20'>
                    <h2 className='font-semibold leading-none' style={{ color: '#7251F4' }}>From Attendee to Content Creator</h2>
                    <p className='my-8'>Create a buzz with instant user-generated content. Our auto-text and hashtag generator prompts your attendees to share their event photos on social media, driving 10x visibility for your events.</p>
                    <Link to="/request-a-demo/" className={`${btn} ${btnPrimary} text-xs mt-5 mb-10 md:mb-0 relative w-max group`}>
                    <span className='mr-4 ml-2'>Get started</span>
                    <WhiteArrowRight className='transition duration-300 ease-in-out group-hover:translate-x-2 mr-2' />
                    </Link>
                </div>
            </div>
            <div className='md:flex mt-32 px-10 md:px-0'>
                <div className='md:w-1/2 md:pr-10'>
                    <StaticImage class='my-4' height={90} src='../../images/ai_dist_custom_1.png' alt='' />
                    <h3 className='font-semibold text-2xl leading-none mb-10' style={{ color: '#7251F4' }}>Branded <br /> Gallery</h3>
                    <p className=''>Create stunning galleries with custom branding to reinforce your event's identity.</p>
                </div>
                <div className='md:w-1/2 md:pl-10'>
                    <StaticImage height={120} src='../../images/ai_dist_custom_2.png' alt='' />
                    <h3 className='font-semibold text-2xl leading-none mb-10' style={{ color: '#7251F4' }}>Search with  <br />a Selfie</h3>
                    <p className=''>Your attendees can spot their high-resolution pictures by simply clicking a selfie.</p>
                </div>
            </div>
            <div className='md:flex mt-20 px-10 md:px-0'>
                <div className='md:w-1/2 md:pr-10'>
                    <StaticImage height={100} src='../../images/ai_dist_custom_3.png' alt='' />
                    <h3 className='font-semibold text-2xl leading-none mb-10 mt-8' style={{ color: '#7251F4' }}>Auto-organize  <br />with Faces Tab</h3>
                    <p className=''>Automatically groups faces based on photos they appear in for easy searching and curating!</p>
                </div>
                <div className='md:w-1/2 md:pl-10'>
                    <StaticImage height={100} src='../../images/ai_dist_custom_4.png' alt='' />
                    <h3 className='font-semibold text-2xl leading-none mb-10 mt-8' style={{ color: '#7251F4' }}>Sponsored content <br />Intergration</h3>
                    <p className=''>Spotlight your sponsors with in-gallery video/static ads, logo integrations and branded photo watermarks.</p>
                </div>
            </div>
            <div className='md:flex mt-20 px-10 md:px-0'>
                <div className='md:w-1/2 md:pr-10'>
                    <StaticImage height={100} src='../../images/ai_dist_custom_5.png' alt='' />
                    <h3 className='font-semibold text-2xl leading-none mb-10 mt-8' style={{ color: '#7251F4' }}>Photo-Powered   <br />Networking</h3>
                    <p className=''> Access key attendee information by tapping on their faces in the event photo gallery.</p>
                </div>
                <div className='md:w-1/2 md:pl-10'>
                    <StaticImage height={100} src='../../images/ai_dist_custom_6.png' alt='' />
                    <h3 className='font-semibold text-2xl leading-none mb-10 mt-8' style={{ color: '#7251F4' }}>Data-Driven<br />Insights</h3>
                    <p className=''>Leverage photo engagement data and insights to refine and improve future events.</p>
                </div>
            </div>
        </div>
      </section>
    );
  }
  
  export default Index;
