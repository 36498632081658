import { StaticImage } from "gatsby-plugin-image";

import icon1 from '../../images/svg/1.svg';
import icon2 from '../../images/svg/2.svg';
import icon3 from '../../images/svg/3.svg';
import icon4 from '../../images/svg/4.svg';

const redSummitData =
    {
        color: '#E3F6FF',
        heroTitle: `Explore the Premagic upgrade that made the RED Summit unforgettable`,
        heroImage: <StaticImage src="../../images/case-study/redsummit/1.png" alt="" />,
        metrics: {
            values: [
                {
                    label: 'Attendees',
                    value: '1,100',
                },
                {
                    label: 'Photos',
                    value: '5,500',
                },
                {
                    label: 'Photo Downloads',
                    value: '1,200',
                },
                {
                    label: 'Social Posts',
                    value: '260',
                }
            ],
            title: 'Background',
            content: `Real Estate Development (RED) Summit Saudi Arabia - Europe Edition is an event that brings together top project owners, designers, architects, and contractors in Saudi Arabia and the Middle East to meet with suppliers and solution providers from around the world in a relaxed environment on a face-to-face meeting basis in Europe.`,
            footer: `The summit was organized by GBB (Global Business to Business), a dynamic organization dedicated to revolutionizing B2B interactions globally. They specialize in hosting transformative events and facilitating business growth and connections.`,
            host: 'GBB',
            organizer: 'GBB',
            industry: 'Real Estate'
        },
        images: [
            {
                img: <StaticImage src="../../images/case-study/redsummit/2.png" alt="" />
            },
            {
                img: <StaticImage src="../../images/case-study/redsummit/3.png" alt="" />
            },
        ],
        gallery: <StaticImage src="../../images/case-study/redsummit/gallery.png" alt="" />,
        caseStudyData: [
            {
                title: 'The Challenge',
                subheading: 'Boost attendee journey with authentic content',
                info1: `The 13th edition of the RED Summit was a moment of excitement for the team, including Head of Operations, Usha Srinivas, and Marketing Manager, Sahana Kathvate. They were eager to bring fresh and innovative ideas to their event strategy. The RED Summit, known for its face-to-face meetings, brand presentations, knowledge sessions, and networking opportunities, presented them with a challenge: delivering event photos seamlessly to all attendees.`,
                testimonial: {
                    img: <StaticImage src="../../images/case-study/redsummit/avatar.png" alt="" />,
                    message: `“ We wanted to engage a platform that could promote the event and its social presence through the attendees. It can be videos, photos, reviews, or anything but most importantly content that is customer-centric, cost-effective, and fully authentic content. “`,
                    name: 'Usha Srinivas',
                    info: `Head of Operations, <br />GBB Venture`
                },
                info2: `In the traditional approach, sorting through and manually distributing all the photos to the audience would take days. This delay could have resulted in a missed opportunity to convert user-generated content.`
            },
            {
                title: 'The Solution',
                subheading: 'An engaging and multipurpose event marketing tool ',
                info1: `Premagic emerged as the ultimate solution to streamline event photo management and drive attendee engagement. With AI-powered capabilities, Premagic eliminated the need for manual photo sorting.<br /><br />
                Attendees could register pre-event and submit a selfie, ensuring prompt delivery of face-matched event photos. Employing Premagic's AI-based photo delivery on-site would resolve the issue of delayed photo sharing, kickstarting social engagement from day one.<br /><br />
                They were highly satisfied with how smoothly Premagic operated, without any unnecessary hiccups or confusion. Sahana Kathvate expressed, "When you're managing live events and working with critical clients, you need a tool that's dependable and ensures tasks are completed because there are no second chances in live events."`
            },
            {
                title: 'The Results',
                subheading: '',
                info1: `Leveraging Premagic to elevate attendee experience, the RED Summit successfully fulfilled its commitment to delivering a world-class event. Here's how GBB and Premagic joined forces to achieve this objective.`,
                gridViewData: [{
                    icon: <img src={icon1} alt="" />,
                    title: 'Simplified Interactions for Enhanced UGC',
                    description: `Seamlessly streamlined interactions to inspire more user-generated content and foster attendee engagement.`
                },
                {
                    icon: <img src={icon2} alt="" />,
                    title: 'Detailed Analytics for Performance Enhancement',
                    description: `Leveraged comprehensive data insights to fine-tune event performance and achieve better results.`

                },
                {
                    icon: <img src={icon3} alt="" />,
                    title: 'The Ultimate UGC Tool for User Engagement',
                    description: `Provided a top-notch solution to promote user engagement and generate authentic content for events.`

                },
                {
                    icon: <img src={icon4} alt="" />,
                    title: 'Real-Time Photo Distribution',
                    description: `Enabled immediate photo sharing, engaging attendees and amplifying event impact instantly.`

                }
                ]
            },
            {
                title: 'The Future',
                subheading: `Enhancing Global Experiences with Premagic`,
                info1: `With the success of the RED Summit Saudi Arabia - Europe Edition 2023, GBB and Premagic are excited to embark on a journey to elevate future events.`,
                testimonial: {
                    img: <StaticImage src="../../images/case-study/redsummit/avatar.png" alt="" />,
                    message: `“ GBB and Premagic have a promising calendar of global events, and we're eager to continue our partnership for shared growth and success. “`,
                    name: 'Usha Srinivas',
                    info: `Head of Operations, <br />GBB Venture`
                }, 
                info2: `She also acknowledges that while there are various platforms in the market that offer photo distribution, Premagic and its event marketing solutions is worth exploring.`
            }
        ]
    }



export default redSummitData;