import React from 'react';
import { Link } from 'gatsby';

import { StaticImage } from "gatsby-plugin-image";
import { btn, btnPrimary } from '../../../ds/button.module.css';

import { WhiteArrowRight } from "../../../ds/Shapes";

import useWindowDimensions from '../../../utils/useWindowDimensions';


const logoLists = [ 
  <StaticImage
    className="mb-14 w-20 mx-8 flex items-center"
    src="../../../images/client-logos/1.png"
    alt="weva"
    objectFit="contain"
  />,
  <StaticImage
    objectFit="contain"
    className="mb-14 w-28 h-12 mx-8"
    src="../../../images/client-logos/2.png"
    alt="client magic motion media"
  />,

  <StaticImage
    objectFit="contain"
    className="mb-14 w-28 h-12 mx-8"
    src="../../../images/client-logos/3.png"
    alt="lumiere"
  />,
  <StaticImage
    className="mb-14 w-28 mx-8 flex items-center"
    src="../../../images/client-logos/4.png"
    alt="weva"
  />,
  <StaticImage
    objectFit="contain"
    className="mb-14 w-24 mx-8"
    src="../../../images/client-logos/5.png"
    alt="client magic motion media"
  />,
  <StaticImage
    objectFit="contain"
    className="mb-14 w-36 mx-8"
    src="../../../images/client-logos/7.png"
    alt="client magic motion media"
  />,
  <StaticImage
    className="mb-14 w-24 mx-8"
    src="../../../images/client-logos/9.png"
    alt="weva"
  />,

  <StaticImage
    className="mb-14 w-12 mx-8"
    src="../../../images/client-logos/10.png"
    alt="weva"
  />,
  <StaticImage
    className="mb-14 w-10 mx-8"
    src="../../../images/client-logos/11.png"
    alt="weva"
  />,
  <StaticImage
    className="mb-14 w-12 mx-8"
    src="../../../images/client-logos/12.png"
    alt="weva"
  />,
  <StaticImage
    className="mb-14 w-16 mx-8"
    src="../../../images/client-logos/13.png"
    alt="weva"
  />,
  <StaticImage
    className="mb-14 w-40 mx-8"
    src="../../../images/client-logos/14.png"
    alt="weva"
  />,
  <StaticImage
    className="mb-14 w-24 mx-8"
    src="../../../images/client-logos/15.png"
    alt="weva"
  />,
  <StaticImage
    className="mb-14 w-32 mx-8"
    src="../../../images/client-logos/16.png"
    alt="weva"
  />,
  <StaticImage
    className="mb-14 w-44 mx-8"
    src="../../../images/client-logos/17.png"
    alt="weva"
  />,
  <StaticImage
    className="mb-14 w-32 mx-8"
    src="../../../images/client-logos/18.png"
    alt="weva"
  />,
  <StaticImage
    className="mb-14 w-32 mx-8"
    src="../../../images/client-logos/19.png"
    alt="weva"
  />,
  <StaticImage
    className="mb-14 w-32 mx-8"
    src="../../../images/client-logos/20.png"
    alt="weva"
  />

];


export function MoreBrands() {

  const { width } = useWindowDimensions();
  const isMobileUp = width >= 1080;
  const containerWidth = isMobileUp ? '1080px' : '100%';
  
  return (
    <section className='mb-20'>
      <h2 className='text-center text-3xl md:text-5xl text-black font-bold mt-36 mb-28 leading-snug px-10 md:px-0'>More such inspiring brands & their <br />success stories</h2>
      <div className="mx-auto flex flex-wrap items-center justify-center" style={{ width: containerWidth, filter: 'grayscale(1)' }}>
        {
          logoLists.map(item => item)
        }
      </div>
    </section>
  )
}

export default MoreBrands;