import { StaticImage } from 'gatsby-plugin-image';
import useWindowDimensions from '../../utils/useWindowDimensions';
import { SmilingFace, MultiStars, Rocket, SpeedoMeter, Box, OrangeTick } from '../../ds/Shapes';
import { useState } from 'react';
import { Link } from 'gatsby';
import {
  btn,
  btnSmall,
  btnDefault,
  btnPrimary,
} from '../../ds/button.module.css';
import { WhiteArrowRight } from '../../ds/Shapes';

export enum TAB_TYPES {
    CRAFT_PERFECT_REGISTRATION_FORM = 'craft_perfect_registration_form',
    TAKE_CONTROL_OF_ATTENDEE_LIST = 'take_control_of_attendee_list',
    CHECK_INS_MADE_EASY = 'check_ins_made_easy',
    CUSTOM_EVENT_BADGES = 'custom_event_badges',
    CONNECT_WHERE_IT_MATTERS = 'connect_where_it_matters',
    DATA_AT_YOUR_FINGERTIPS = 'data_at_your_fingertips'
  }

const KEY_FEATURES = {
    [TAB_TYPES.CRAFT_PERFECT_REGISTRATION_FORM]: {
        tabTitle: 'Craft the perfect registration form',
        description: 'Design your ideal registration form with unlimited custom fields, capturing exactly the information you need.',
        image: <StaticImage height={480} src='../../images/reg_1.png' alt='' />,
        icon: (color) => <SmilingFace color={color} />
    },
    [TAB_TYPES.TAKE_CONTROL_OF_ATTENDEE_LIST]: {
        tabTitle: 'Take control of your attendee list',
        description: 'Approve or decline attendees with a click, keeping your event as exclusive or open as you want.',
        image: <StaticImage height={480} src='../../images/reg_2.png' alt='' />,
        icon: (color) => <MultiStars color={color} />
    },
    [TAB_TYPES.CHECK_INS_MADE_EASY]: {
        tabTitle: 'Check-ins made easy',
        description: 'Enable swift, secure attendance marking with face recognition, eliminating the risks of  shareable badges, QR codes, and RFIDs.',
        image: <StaticImage height={480} src='../../images/reg_3.png' alt='' />,
        icon: (color) => <Rocket color={color} />
    },
    [TAB_TYPES.CUSTOM_EVENT_BADGES]: {
        tabTitle: 'Custom event badges',
        description: 'Easily create ready-to-print badges customized to align perfectly with your brand.',
        image: <StaticImage height={480} src='../../images/reg_4.png' alt='' />,
        icon: (color) => <SpeedoMeter color={color} />
    },
    [TAB_TYPES.CONNECT_WHERE_IT_MATTERS]: {
        tabTitle: 'Connect where it matters',
        description: `Boost engagement by communicating with attendees via WhatsApp and email, where they're most responsive.`,
        image: <StaticImage height={480} src='../../images/reg_5.png' alt='' />,
        icon: (color) => <Box color={color} />
    },
    [TAB_TYPES.DATA_AT_YOUR_FINGERTIPS]: {
        tabTitle: 'Data at your fingertips',
        description: 'Effortlessly sync event data across platforms with seamless import and export capabilities.',
        image: <StaticImage height={480} src='../../images/reg_6.png' alt='' />,
        icon: (color) => <Box color={color} />
    },
}

export function HowItWorks() {
    const { width } = useWindowDimensions();
    const isMobileUp = width >= 1080;
    const containerWidth = isMobileUp ? '1080px' : '100%';
    const [activeTab, setActiveTab] = useState(TAB_TYPES.CRAFT_PERFECT_REGISTRATION_FORM);

    return (
      <section className='mb-20' style={{ backgroundColor: '#E3F2ED' }}>
        <div className='mx-auto py-20 flex items-center md:w-[1080px]'>
            <div className='w-1/2 pr-28'>
            <div className='bg-white rounded-xl p-5 overflow-scroll no-scrollbar mx-5 md:mx-0'>

                {
                    Object.keys(KEY_FEATURES).map(key => (
                        <div className='mr-2 md:mx-0 flex items-center p-4 rounded-xl bg-white cursor-pointer hover:bg-purple-50 transition duration-300 ease-in-out' style={{ backgroundColor: activeTab === key ? '#7251F4': '' }} onClick={() => setActiveTab(key)}>
                            <div className='scale-125 md:scale-100'>
                                {KEY_FEATURES[key].icon(activeTab === key ? '#FFFFFF' : '#121212')}
                            </div>
                            <p className={`ml-3 font-medium text-sm ${activeTab === key ? 'text-white': 'text-black'} `}>{KEY_FEATURES[key].tabTitle}</p>
                        </div>
                    ))
                }
            </div>
            </div>
            <div className='w-1/2 mt-14 px-10 md:px-0'>
                <div className='h-[480px] flex items-center'>
                    {KEY_FEATURES[activeTab].image}
                </div>
                <div className=''>
                    <h2 className='text-black text-3xl md:w-2/3 leading-relaxed font-bold mb-2 pr-20 leading-tight md:leading-tight relative z-20' style={{ color: '#7251F4' }}>{KEY_FEATURES[activeTab].tabTitle}</h2>
                    <p className='text-sm mb-5 md:w-2/3 leading-relaxed'>{KEY_FEATURES[activeTab].description}</p>
                </div>
            </div>
        </div>
      </section>
    );
  }
  
  export default HowItWorks;
