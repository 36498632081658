import React from 'react';
import Flickity from 'react-flickity-component';
import { StaticImage } from 'gatsby-plugin-image';

import {
  clientLogosContainer,
  whiteShadow,
  flipHorizontal,
} from './logo-strip.module.css';
import useWindowDimensions from '../../utils/useWindowDimensions';

const flickityOptions = {
  autoPlay: 2000,
  wrapAround: true,
  selectedAttraction: 0.01,
  friction: 0.2,
  cellAlign: 'left',
  contain: true,
  pageDots: false,
  prevNextButtons: false,
  pauseAutoPlayOnHover: false
};

export default function LogoStrip(props: { slider?: boolean; fullWidth?: boolean }) {
  const { fullWidth } = props;
  const { width } = useWindowDimensions();
  const isMobileUp = width >= 1080;
  const containerWidth = isMobileUp ? '1080px' : '100%';
  const padding = fullWidth ? '' : 'py-16'
    return (
      <section className={`${padding}`}>
        <div className="mx-auto" style={{ width: containerWidth }}>
          <div className={`${clientLogosContainer} rounded-xl relative opacity-50`}>
            <div
              className={`${whiteShadow} absolute left-0 top-0 w-20 bottom-0 z-10`}
            />
            <div
              className={`${whiteShadow} ${flipHorizontal} absolute right-0 top-0 w-20 bottom-0 z-10`}
            />
            <Flickity
              className="carousel grayscale" // default ''
              elementType="div"
              options={flickityOptions} // takes flickity options {}
              disableImagesLoaded={false} // default false
              reloadOnUpdate // default false
              static // default false
            >
              <StaticImage
                className="w-20 mt-3 mx-8 flex items-center"
                src="../../images/client-logos/1.png"
                alt="weva"
                objectFit="contain"
              />
              <StaticImage
                objectFit="contain"
                className="w-28 h-12 mx-8"
                src="../../images/client-logos/2.png"
                alt="client magic motion media"
              />

              <StaticImage
                objectFit="contain"
                className="w-28 h-12 mx-8"
                src="../../images/client-logos/3.png"
                alt="lumiere"
              />
              <StaticImage
                className="w-28 mx-8 mt-2 flex items-center"
                src="../../images/client-logos/4.png"
                alt="weva"
              />
              <StaticImage
                objectFit="contain"
                className="w-24 mt-1 mx-8"
                src="../../images/client-logos/5.png"
                alt="client magic motion media"
              />
              <StaticImage
                objectFit="contain"
                className="w-36 mx-8 mt-1"
                src="../../images/client-logos/7.png"
                alt="client magic motion media"
              />
              <StaticImage
                className="w-24 mx-8 mt-2"
                src="../../images/client-logos/9.png"
                alt="weva"
              />

              <StaticImage
                className="w-12 mt-2 mx-8"
                src="../../images/client-logos/10.png"
                alt="weva"
              />
              <StaticImage
                className="w-10 mx-8 mt-2"
                src="../../images/client-logos/11.png"
                alt="weva"
              />
            </Flickity>    
            <Flickity
              className="carousel grayscale mt-10" // default ''
              elementType="div"
              options={flickityOptions} // takes flickity options {}
              disableImagesLoaded={false} // default false
              reloadOnUpdate // default false
              static // default false
            >
              <StaticImage
                className="w-12 mx-8 mt-2"
                src="../../images/client-logos/12.png"
                alt="weva"
              />
              <StaticImage
                className="w-16 mx-8 mt-2"
                src="../../images/client-logos/13.png"
                alt="weva"
              />
              <StaticImage
                className="w-40 mx-8 mt-5"
                src="../../images/client-logos/14.png"
                alt="weva"
              />
              <StaticImage
                className="w-40 mx-8 mt-7"
                src="../../images/client-logos/22.png"
                alt="weva"
              />
              <StaticImage
                className="w-24 mx-8 mt-5"
                src="../../images/client-logos/15.png"
                alt="weva"
              />
              <StaticImage
                className="w-32 mx-8 mt-2"
                src="../../images/client-logos/16.png"
                alt="weva"
              />
              <StaticImage
                className="w-44 mx-8 mt-6"
                src="../../images/client-logos/17.png"
                alt="weva"
              />
              <StaticImage
                className="w-32 mx-8 mt-3"
                src="../../images/client-logos/18.png"
                alt="weva"
              />
              <StaticImage
                className="w-32 mx-8 mt-1"
                src="../../images/client-logos/19.png"
                alt="weva"
              />
              <StaticImage
                className="w-32 mx-8 mt-4"
                src="../../images/client-logos/20.png"
                alt="weva"
              />
            </Flickity>
          </div>
        </div>
      </section>
    );
}
