import React, { useState } from 'react';
import {
  btn,
  btnSmall,
  btnPrimary
} from '../../ds/button.module.css';
import { WhiteArrowRight } from '../../ds/Shapes';

type Props = {
  show: boolean;
  toggleDetailsComponent: (status: boolean) => void;
  toggleGuestListComponent: (status: boolean) => void;
  addData: (key: string, value: string) => void;
  trackEvent: (eventName: string) => void;
}
export function Details(props: Props) {

  const { show, toggleDetailsComponent, toggleGuestListComponent, addData, trackEvent } = props; 

  if(!show) return null;

  return (
    <div className='border shadow-lg p-10 rounded-2xl border-slate-100'>
      <h4 className='font-semibold text-black'>{"Add Your Details ✍️"}</h4>
      <p className='text-sm text-black opacity-70 mt-5 text-sm text-zinc-600'>Please enter the following details, and we'll let you know as soon as your gallery is ready.</p>
      <form onSubmit={()=> {toggleGuestListComponent(true); toggleDetailsComponent(false); trackEvent('form_submit')}}>
      <div className="md:grid md:grid-cols-2 md:gap-4 mt-10">
        <div className='mb-5 md:mb-0'>
          <p className='text-sm text-black opacity-80 mb-2'>Full Name</p>
          <input required onChange={(e) => addData('host_name', e.target.value)} className='py-3 px-5 border rounded-lg focus:outline-none focus:border-black grow' />
        </div>
        <div className='mb-5 md:mb-0'>
          <p className='text-sm text-black opacity-80 mb-2'>Email</p>
          <input required type='email' onChange={(e) => addData('host_email', e.target.value)} className='py-3 px-5 border rounded-lg focus:outline-none focus:border-black grow' />
        </div>
        <div>
          <p className='text-sm text-black opacity-80 mb-2'>Event Name</p>
          <input required onChange={(e) => addData('event_name', e.target.value)} className='py-3 px-5 border rounded-lg focus:outline-none focus:border-black grow' />
        </div>
      </div>
      <button id="event_details_success" className={`${btn} ${btnPrimary} ${btnSmall} text-xs relative group mt-10`} type='submit'>
        <span className='mr-4 ml-2'>Continue</span>
        <WhiteArrowRight className='transition duration-300 ease-in-out group-hover:translate-x-2 mr-2' />
      </button>
      </form>
    </div>
  )
}