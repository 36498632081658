
import React from 'react';
import Layout from '../../../components/Layout';
import Header from '../../../components/Header';
import CaseStudy, { CASE_STUDY_TYPES } from '../../../components/case-study/CaseStudy';
import ThumbnailImage from '../../../images/thumbnails/showsofindia.png';

export function Index() {
  return (
    <Layout 
    title="Discover how EVA Live rekindled live entertainment at Shows of India with Premagic."
    description="Discover how EVA Live rekindled live entertainment at Shows of India with Premagic."
    metaImage={ThumbnailImage}>
      <Header />
      <CaseStudy type={CASE_STUDY_TYPES.SHOWSOFINDIA} />
    </Layout>
  );
}

export default Index;