import { getAllQueryVariables, getUserOSName } from '../utils/BrowserUtils';
import { get, post } from '../utils/FetchUtils';
import { getUserLocation } from '../utils/UserLocationUtils';
import { trackFBEvents, trackGAEvents } from './EventTracker';

const isProd = process.env.NODE_ENV === 'production';

const API_ROOT = isProd ? 'https://api.premagic.com' : 'http://localhost:5000';
const API_URLS = {
  PARSE: `${API_ROOT}/external_uploader/stat/`,
  UPLOADER: `${API_ROOT}/external_uploader/`,
};

const ERRORS_INFO = {
  ALREADY_REGISTERED:
    'You have already registered with us! Login to app.premagic.com',
  TRY_AGAIN:
    'Something went wrong... Try again or write to support@premagic.com',
  PASSWORD_LENGTH: 'Password is too short, it should be more that 6 characters',
  PHONE_INVALID:
    'Phone number is invalid, Enter a valid phone number eg. +919605547547',
};

export function parseExternalLink(url: string): Promise<any> {

  return get(`${API_URLS.PARSE}?url=${url}`)
    .then((response) => {
      if(response.success) {
        trackGAEvents('parse_external_link_success', {
          eventCategory: 'acquire',
          eventAction: 'button_click',
          eventLabel: 'parse_external_link_success',
        });
        return response;
      }
      return response;
    })
    .catch((response) =>{
      if ('error' in response) {
        trackGAEvents(`parse_external_link_failed_${response?.error?.code}`, {
          eventCategory: 'acquire',
          eventAction: 'button_click',
          eventLabel: 'parse_external_link_failed',
        });
        return response;
      } 
      return Promise.reject({ error: response.error || ERRORS_INFO.TRY_AGAIN })
    }
    );
}

export function externalUpload(data: any): Promise<any> {

  return post(API_URLS.UPLOADER, data)
    .then((response) => {
      if (response.success) {
        trackGAEvents('upload_external_gallery_success', {
          eventCategory: 'acquire',
          eventAction: 'button_click',
          eventLabel: 'upload_external_gallery_success',
        });
        return response;
      }
      return Promise.reject({ error: ERRORS_INFO.TRY_AGAIN });
    })
    .catch((response) => {
      trackGAEvents(`upload_external_gallery_failed_${response?.error?.code}`, {
        eventCategory: 'acquire',
        eventAction: 'button_click',
        eventLabel: 'upload_external_gallery_failed',
      });
      return Promise.reject({ error: response.error || ERRORS_INFO.TRY_AGAIN })
    }
    );
}
