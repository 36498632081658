import { Link } from 'gatsby';
import useWindowDimensions from '../../utils/useWindowDimensions';
import { StaticImage } from 'gatsby-plugin-image';
import { btn, btnPrimary } from '../../ds/button.module.css';
import { CASE_STUDY_TYPES } from './CaseStudy';
import { BlackArrowRight, WhiteArrowRight, ArrowWhiteDown } from '../../ds/Shapes';
import { useState } from 'react';

type Props =  {
  currentPage: CASE_STUDY_TYPES | 'home',
  itemsToShow: 3 | 6;
}

const moreCaseStudies = [
  {
    type: 'mercedesbenz',
    title : 'Explore how Coastal Star Enhance the Mercedes-Benz Experience with premagic',
    url: '/case-study/mercedes-benz/',
    image: <StaticImage src='../../images/case-study/morecasestudy/mercedesbenz.png' alt="" />
  },
  {
    type: 'wowawards',
    title : 'Find out how WOW Awards uses Premagic to wow their audience',
    url: '/case-study/wowawards/',
    image: <StaticImage src='../../images/case-study/morecasestudy/wowawards.png' alt="" />
  },
  {
    type: 'asiaberlin',
    title : 'Explore how AsiaBerlin transformed the ABS2023 experience with Premagic',
    url: '/case-study/asiaberlin/',
    image: <StaticImage src='../../images/case-study/morecasestudy/asiaberlin.png' alt="" />
  },
  {
    type: 'nasscom',
    title : 'Learn how Nasscom uses Premagic to empower sponsor audience',
    url: '/case-study/nasscom/',
    image: <StaticImage src='../../images/case-study/morecasestudy/nasscom.png' alt="" />,
    hideInHome: true
  },
  {
    type: 'redsummit',
    title : 'Explore the Premagic upgrade that made the RED Summit unforgettable',
    url: '/case-study/real-estate-development-summit/',
    image: <StaticImage src='../../images/case-study/morecasestudy/redsummit.png' alt="" />
  },
  {
    type: 'umagine',
    title: `Learn how Premagic helped in UmagineChennai's grand success`,
    url: '/case-study/umagine/',
    image: <StaticImage src='../../images/case-study/morecasestudy/umagine.png' alt="" />,
    hideInHome: true
  },
  {
    type: 'saasboomi',
    title : 'Find how SaaSBOOMi achieved real-time engagement with Premagic',
    url: '/case-study/saasboomi/',
    image: <StaticImage src='../../images/case-study/morecasestudy/saasboomi.png' alt="" />,
    hideInHome: true
  },
  {
    type: 'showsofindia',
    title : 'Learn how EVA Live rekindled live entertainment at Shows of India with Premagic.',
    url: '/case-study/showsofindia/',
    image: <StaticImage src='../../images/case-study/morecasestudy/showsofindia.png' alt="" />
  },
  {
    type: 'aio',
    title : 'Discover how Premagic Resolved DAM Challenges at AIOC Kochi',
    url: '/case-study/aioc/',
    image: <StaticImage src='../../images/case-study/morecasestudy/aio.png' alt="" />
  },
  {
    type: 'jsconf',
    title : 'Learn how Premagic optimized the attendee experience at JSConf India',
    url: '/case-study/jsconf/',
    image: <StaticImage src='../../images/case-study/morecasestudy/jsconf.png' alt="" />
  },
]

export default function MoreCaseStudy(props: Props) {
  const { currentPage = 'home', itemsToShow = 6 } = props;
  const { width } = useWindowDimensions();
  const isMobileUp = width >= 1080;
  const containerWidth = isMobileUp ? '1080px' : '100%';

  let moreCaseStudiesToShow = moreCaseStudies.filter(item => item.type !== currentPage);
  moreCaseStudiesToShow = currentPage === 'home' ? moreCaseStudiesToShow.filter(item => !item?.hideInHome) : moreCaseStudiesToShow;
  const [isGridExpanded, toggleGridView] = useState(false); 
  return (
    <section className={`${currentPage !== 'home' ? 'mt-40 mb-20': 'mb-20'}`}>
      { currentPage !== 'home' && <h2 className='text-black text-center text-3xl md:text-5xl font-bold my-10'>More related success stories</h2>}
      <div className="px-5 md:px-0 mx-auto md:flex md:flex-wrap" style={{ width: containerWidth }}>
        {
          moreCaseStudiesToShow.slice(0, isGridExpanded ? moreCaseStudiesToShow.length : itemsToShow).map(item => (
            <div className='rounded-xl p-7 md:w-1/3 group'>
              <div className='p-3 rounded-xl flex flex-col border border-white group-hover:border-slate-300 pb-5'>
                <div className='rounded-xl overflow-hidden'>{item.image}</div>
                <p className='p-5 font-medium text-base text-black mb-5'>{item.title}</p>
                <Link to={item.url} className='flex text-base mt-auto items-center ml-5'>
                  <span className='mr-2 text-sm font-medium text-black'>Read Full Article</span>
                  <BlackArrowRight className='transition delay-100 duration-300 ease-in-out group-hover:translate-x-2' />
                </Link>
              </div>
            </div>
          ))
        }
      </div>
      <div className='text-center mt-10'>
        {!isGridExpanded && moreCaseStudiesToShow.length > itemsToShow && 
          <button className={`${btn} ${btnPrimary} w-max`} onClick={() => toggleGridView(true)}>
            <span className='mr-2'>Show more</span>
            <span className='block'>
              <ArrowWhiteDown />
            </span>
          </button>
        }
        {isGridExpanded && moreCaseStudiesToShow.length > itemsToShow && 
        <button className={`${btn} ${btnPrimary} w-max`} onClick={() => toggleGridView(false)}>
          <span className='mr-2'>Show less</span>
            <span className='block rotate-180'>
              <ArrowWhiteDown />
            </span>
        </button>}
      </div>
    </section>
  );
}