import { Link } from "gatsby";
import { btn, btnPrimary } from '../ds/button.module.css';
import { WhiteArrowRight } from "../ds/Shapes";
import { StaticImage } from "gatsby-plugin-image";
import Header from "../components/Header";
import Layout from "../components/Layout";

export default function index() {
    return (
        <Layout title="Sell-out your Events with Customizable Event Marketing Posters" description="Achieve 10X more referrals through each attendee using personalized event marketing posters.">
    <div className=''>
        <div className="" style={{ backgroundColor: '#7251F4' }}>
            <Header isWhiteLogo />
        </div>

        <section className="py-10" style={{ backgroundColor: '#7251F4' }}>
        <div className="px-5 md:px-0 mx-auto md:w-[1080px]">
            <div className="md:flex items-center h-[500px]">
                <div className="md:w-1/2 flex flex-col justify-center text-left">
                    {/* <h1 className="text-3xl md:text-5xl font-bold mb-4 text-white"><span style={{ color: '#61F2C2' }}>DOUBLE</span> YOUR <br />ATTENDEES</h1> */}
                    <h1 className="text-3xl md:text-5xl font-bold mb-4 text-white">Sell-out your Events with Customizable Event Marketing Posters</h1>
                    <p className="text-white"><span style={{ color: '#61F2C2' }}>Achieve 10X</span> more referrals through each attendee <br />using personalized event marketing posters. </p>
                    <Link to="/request-a-demo/" className={`${btn} ${btnPrimary} text-xs mt-10 md:mt-32 mb-10 md:mb-0 relative w-max group`}>
                    <span className='mr-4 ml-2'>Show me how</span>
                    <WhiteArrowRight className='transition duration-300 ease-in-out group-hover:translate-x-2 mr-2' />
                    </Link>
                </div>
                <div className="md:w-1/2 md:ml-0 relative">
                    <div className="md:absolute -left-60 -mx-10 md:mx-0 md:w-[900px] -top-8">
                        <StaticImage src="../images/advocacy_hero.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
        </section>  
        <section className="md:pt-10">
        <div className="px-5 md:px-0 mx-auto md:w-[1080px]">
            <div className="flex flex-col md:flex-row flex-col-reverse">
                <div className="md:w-1/2 flex flex-col justify-center text-left md:pr-10 md:mt-0">
                    <h4 className="font-bold text-2xl mb-5" style={{ color: '#7251F4' }}>How it works</h4>
                    <h1 className="text-3xl md:text-4xl font-bold md:leading-snug mb-4" style={{ color:'#7251F4' }}>Grow your event <span style={{ color: '#FF6550' }}>10x</span> with event marketing posters</h1>
                    <p>Premagic helps grow your event with community marketing
                    by giving your attendees easy-to-share, personalized event content.</p>
                    <Link to="/request-a-demo/" className={`${btn} ${btnPrimary} text-xs mt-10 mb-10 md:mb-0 relative w-max group`}>
                    <span className='mr-4 ml-2'>Show me how</span>
                    <WhiteArrowRight className='transition duration-300 ease-in-out group-hover:translate-x-2 mr-2' />
                    </Link>
                </div>
                <div className="md:w-1/2 md:pl-10 mt-10 md:mt-0 -mr-10 md:mr-0">
                    <StaticImage src="../images/how_it_works_image.png" height={500} alt="" />
                </div>
            </div>
            <div className="md:flex mt-20">
                <div className="md:w-1/2 md:pr-10">
                    <StaticImage src="../images/feature1.png" alt="" />
                </div>
                <div className="md:w-1/2 flex flex-col justify-center text-left mt-10 md:mt-0 md:pl-10">
                    <h1 className="text-3xl md:text-4xl font-bold md:leading-snug mb-2" style={{ color: '#7251F4' }}>Create</h1>
                    <p className="md:pr-32">Create stunning event marketing posters from scratch or customize our engaging templates with your preferred fonts, colors, widgets.</p>
                </div>
            </div>
            <div className="flex flex-col md:flex-row mt-20 md:my-20 flex-col-reverse">
                <div className="md:w-1/2 flex flex-col justify-center text-left md:pr-10 mt-10 md:mt-0">
                    <h1 className="text-3xl md:text-4xl font-bold md:leading-snug mb-2" style={{ color: '#7251F4' }}>Share</h1>
                    <p className="md:pr-32">Share the poster links with your event stakeholders. Add it to registration pages or send via email or WhatsApp. </p>
                </div>
                <div className="md:w-1/2 md:pl-10">
                    <StaticImage src="../images/feature2.png" alt="" />
                </div>
            </div>
            <div className="md:flex mb-20 mt-20 md:mt-0">
                <div className="md:w-1/2 md:pr-10">
                    <StaticImage src="../images/feature3.png" alt="" />
                </div>
                <div className="md:w-1/2 flex flex-col justify-center text-left md:pl-10 mt-10 md:mt-0">
                    <h1 className="text-3xl md:text-4xl font-bold md:leading-snug mb-2" style={{ color: '#7251F4' }}>Personalize</h1>
                    <p className="md:pr-32">Each attendee’s poster is automatically personalized  using their LinkedIn and share it with their network with one simple click.</p>
                </div>
            </div>
            <div className="flex flex-col md:flex-row md:my-20 flex-col-reverse">
                <div className="md:w-1/2 flex flex-col justify-center text-left md:pr-10 mt-10 md:mt-0">
                    <h1 className="text-3xl md:text-4xl font-bold md:leading-snug mb-2" style={{ color: '#7251F4' }}>Measure</h1>
                    <p className="md:pr-32">Effortlessly track the impact of each poster, measure clicks, and identify top advocates with super simple reports.</p>
                </div>
                <div className="md:w-1/2 md:pl-10">
                    <StaticImage src="../images/feature4.png" alt="" />
                </div>
            </div>
        </div>
        </section>
        <section>
            <div className="px-5 md:px-0 mx-auto pt-20 pb-20 md:w-[1080px]">
                <h1 className="hidden md:block font-bold text-5xl text-center leading-tight mb-28" style={{ color:'#7251F4' }}>More ROI without the cost and<br /> friction of traditional marketing<br />
                <span style={{ color: '#FF6550' }}>all for just $1!</span></h1>
                <h1 className="md:hidden font-bold text-4xl text-center leading-tight mb-28" style={{ color:'#7251F4' }}>More ROI without the cost and friction of traditional marketing<br />
                <span style={{ color: '#FF6550' }}>all for just $1!</span></h1>
                <div className="md:flex">
                    <div className="md:w-1/3 md:px-2 mb-20 md:mb-0">
                        <StaticImage className="md:w-64 rounded-xl" src="../images/roi1.png" alt="" />
                        <h1 className="text-2xl mt-7 mb-4 md:text-2xl font-bold md:leading-snug mb-2" style={{ color: '#7251F4' }}>Lots of attendees,<br /> not enough designers?</h1>
                        <p className="md:pr-32">Save upto 2 weeks of effort with dynamically created event marketing posters without the need for a graphic designer. </p>
                    </div>
                    <div className="md:w-1/3 md:px-10 mb-20 md:mb-0">
                        <StaticImage className="md:w-[273px] rounded-xl" src="../images/roi2.png" alt="" />
                        <h1 className="text-2xl mt-7 mb-4 md:text-2xl font-bold md:leading-snug mb-2" style={{ color: '#7251F4' }}>All events, all sizes,<br />same results.</h1>
                        <p className="">From massive multi-day conferences to intimate webinars, our event marketing posters work everywhere.</p>
                    </div>
                    <div className="md:w-1/3 md:pl-20">
                        <StaticImage className="md:w-64 rounded-xl" src="../images/roi3.png" alt="" />
                        <h1 className="text-2xl mt-7 mb-4 md:text-2xl font-bold md:leading-snug mb-2" style={{ color: '#7251F4' }}>Boundless freedom,<br /> unlimited potential</h1>
                        <p className="">Create unlimited number of poster templates. No restrictions on file size. No cap on advocates or target acquisitions.</p>
                    </div>
                </div>
                <div className="text-center">
                    <Link to="/request-a-demo/" className={`${btn} ${btnPrimary} text-xs mt-10 mb-10 md:mb-0 mx-auto relative w-max group`}>
                        <span className='mr-4 ml-2'>Show me how</span>
                        <WhiteArrowRight className='transition duration-300 ease-in-out group-hover:translate-x-2 mr-2' />
                        </Link>
                </div>
            </div>
        </section>
        

        <section className='py-20 md:py-0' style={{ backgroundColor: '#7251F4' }}>
        <div className="px-5 md:px-0 mx-auto md:flex py-10 items-center md:w-[1080px]">
          <div className='md:w-1/2 flex flex-col justify-center relative mb-20 md:mb-0'>
            
            <StaticImage class='' src="../images/posters.png" alt="" />
            
          </div>
          <div className='md:w-1/2'>
            <h4 className="text-white text-center text-2xl"><span style={{ color: '#61F2C2' }}>“</span>Effortless for our speakers to create and share their own posters. Quick, easy, and impactful. I love it!<span style={{ color: '#61F2C2' }}>”</span></h4>
            <h5 className="text-2xl text-center mt-10" style={{ color: '#61F2C2' }}>Dana Sakr</h5>
            <p className="text-white text-xl text-center">Marketing Manager, Step</p>
          </div>
        </div>
        </section>
        
       
        <section className='my-20'>
        <div className="px-5 md:px-0 mx-auto flex py-10 items-center flex-wrap md:w-[1080px]">
            <div className="w-1/2 mb-20 md:mb-0 md:w-1/4 text-center">
                <StaticImage class='w-24' src="../images/metric_icon1.png" alt="" />
                <h4 style={{ color: '#FF6550' }} className="text-4xl font-bold my-5">60%</h4>
                <p>Cost<br />
                savings</p>
            </div>
            <div className="w-1/2 mb-20 md:mb-0 md:w-1/4 text-center">
                <StaticImage class='w-20' src="../images/metric_icon2.png" alt="" />
                <h4 style={{ color: '#FF6550' }} className="text-4xl font-bold my-5">40%</h4>
                <p>More<br />
                attendees</p>
            </div>
            <div className="w-1/2 md:w-1/4 text-center">
                <StaticImage class='w-20' src="../images/metric_icon3.png" alt="" />
                <h4 style={{ color: '#FF6550' }} className="text-4xl font-bold my-5">90%</h4>
                <p>Increase in<br />
                engagement</p>
            </div>
            <div className="w-1/2 md:w-1/4 text-center">
                <StaticImage class='w-24' src="../images/metric_icon4.png" alt="" />
                <h4 style={{ color: '#FF6550' }} className="text-4xl font-bold my-5">80%</h4>
                <p>increase in<br />
                referral traffic</p>
            </div>
        </div>
        </section>


        {/* <RequestDemo /> */}
       
    </div>      
    </Layout>
    )
}