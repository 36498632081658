import React from 'react';
import Header from '../components/Header';
import Hero from '../components/distribute-attendees-photos-from-the-event/Hero';
import Clients from '../components/landing-page/Clients';
import Layout from '../components/Layout';

import '../index.css';
import Features from '../components/distribute-attendees-photos-from-the-event/Features';
import MagicWithUs from '../components/inner-page/MagicWithUs';
import Feebacks from '../components/landing-page/Feebacks';
import MoreCaseStudy from '../components/case-study/MoreCaseStudy';

export function Index() {
  return (
    <Layout title="Boosting your event marketing with AI">
      <Header />
      <Hero />
      <Features />
      <Clients />
      <Feebacks hasHeading />
      <MoreCaseStudy currentPage='home' itemsToShow={6} />
      <MagicWithUs />
    </Layout>
  );
}

export default Index;