
import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import {
  btn,
  btnDefault,
  playButton,
  playButtonIcon
} from '../../ds/button.module.css';
import { ArrowRightWithGradient } from '../../ds/Shapes';
import { cardBorderBottomBlue } from '../../ds/card.module.css';
import useWindowDimensions from '../../utils/useWindowDimensions';
import { Calendar } from '../../ds/SvgIcons';

const eventTypes = [
  {
    icon: <StaticImage width={32} src='../../images/icons/new/1.png' alt='' />,
    title: 'Conferences',
    description: 'Infuse engagement with just a click'
  },
  {
    icon: <StaticImage width={32} src='../../images/icons/new/2.png' alt='' />,
    title: 'Seminars & Workshops',
    description: 'Upgrade the learning experiences'
  },
  {
    icon: <StaticImage width={32} src='../../images/icons/new/3.png' alt='' />,
    title: 'Trade Shows & Exhibitions',
    description: 'Drive leads & build connections'
  },
  {
    icon: <StaticImage width={32} src='../../images/icons/new/4.png' alt='' />,
    title: 'Product Launches',
    description: 'Engaging product experiences'
  },
  {
    icon: <StaticImage width={32} src='../../images/icons/new/5.png' alt='' />,
    title: 'Team Building Retreats',
    description: 'Build unique experiences to boost morale'
  },
  {
    icon: <StaticImage width={32} src='../../images/icons/new/6.png' alt='' />,
    title: 'Award Ceremonies',
    description: 'Glitz & glamour with long-lasting impact'
  },
  {
    icon: <StaticImage width={32} src='../../images/icons/new/7.png' alt='' />,
    title: 'Business Dinners & Galas',
    description: 'Build connections, form collaborations'
  },
  {
    icon: <StaticImage width={32} src='../../images/icons/new/8.png' alt='' />,
    title: 'Networking Events',
    description: 'Deliver better networking opportunities'
  },
  {
    icon: <StaticImage width={32} src='../../images/icons/new/9.png' alt='' />,
    title: 'Corporate Retreats',
    description: 'Recharge and bond in style'
  },

]


export function EventTypes() {
  const { width } = useWindowDimensions();
  const isMobileUp = width >= 768;
  if(isMobileUp) {
    return (
      <div className='pm-container mx-auto px-6 my-10'>
        <h1 className='text-center font-bold text-5xl mt-10 leading-tight text-black leading-snug'>We’re changing the game, one <br />corporate event at a time</h1>  
        <p className='text-black text-center mt-10 mb-20'>Host Corporate Events that deliver powerful engagement and elevate ROI.</p>
        <div className="grid grid-cols-3 gap-3">
          {
            eventTypes.map(item => (
              <div className='rounded-xl p-8' style={{ backgroundColor: '#F4F7FF' }}>
                {item.icon}
                <h4 className='mt-6 text-black font-semibold text-xl'>{item.title}</h4>
                <p className='text-sm text-black opacity-50 mt-2'>{item.description}</p>
              </div>
            ))
          }
        </div>
      </div>
    );
  }
  return (
    <div className='pm-container mx-auto px-6 my-10'>
        <h1 className='text-center font-bold text-3xl mt-10 leading-tight text-black leading-snug'>We’re changing the game, one corporate event at a time</h1>  
        <p className='text-black text-center mt-10 mb-20'>Whether you want to raise brand awareness, drive revenue or engage attendees, Premagic being the perfect choice.</p>
        <div className="grid gap-3">
          {
            eventTypes.map(item => (
              <div className='rounded-xl p-8' style={{ backgroundColor: '#F4F7FF' }}>
                {item.icon}
                <h4 className='mt-6 text-black font-semibold text-xl'>{item.title}</h4>
                <p className='text-sm text-black opacity-50 mt-2'>{item.description}</p>
              </div>
            ))
          }
        </div>
      </div>
  )
}

export default EventTypes;