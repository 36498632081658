interface RequestError extends Error {
  status: number;
  url: string;
  response: { error: string };
}

export function post(url, data) {
  const formData = new FormData();

  for (const key in data) {
    formData.append(key, data[key]);
  }
  return fetch(url, {
    method: 'POST',
    // headers: {
    //   "Content-Type": "application/json",
    // },
    //  body: JSON.stringify(data),
    body: formData,
  }).then(async (response) => {
    const responseData = await response.json();

    if (response.status >= 200 && response.status < 300) {
      return responseData;
    }
    if (!response.ok) {
      return Promise.reject(responseData);
    }
    return responseData;
  });
}

export function get(url) {
  return fetch(url).then(async (response) => {
    const responseData = await response.json();

    if (response.status >= 200 && response.status < 300) {
      return responseData;
    }

    if (!response.ok) {
      return Promise.reject(responseData);
    }
    return responseData;
  });
}
