
import React from 'react';
import Layout from '../../../components/Layout';
import Header from '../../../components/Header';
import CaseStudy, { CASE_STUDY_TYPES } from '../../../components/case-study/CaseStudy';
import ThumbnailImage from '../../../images/thumbnails/wowawards.png';
import MagicWithUs from '../../../components/inner-page/MagicWithUs';

export function Index() {
  return (
    <Layout 
    title="Find out how WOW Awards uses Premagic to wow their audience"
    description="Find out how WOW Awards uses Premagic to wow their audience"
    metaImage={ThumbnailImage}>
      <Header />
      <CaseStudy type={CASE_STUDY_TYPES.WOWAWARDS} />
    </Layout>
  );
}

export default Index;