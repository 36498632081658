
import React from 'react';
import Layout from '../../../components/Layout';
import Header from '../../../components/Header';
import CaseStudy, { CASE_STUDY_TYPES } from '../../../components/case-study/CaseStudy';
import ThumbnailImage from '../../../images/thumbnails/tdp.png';
import MagicWithUs from '../../../components/inner-page/MagicWithUs';

export function Index() {
  return (
    <Layout 
    title="Learn how Nara Lokesh's Yuvagalam Padayatra used Premagic to drive TDP's success."
    description="Learn how Nara Lokesh's Yuvagalam Padayatra used Premagic to drive TDP's success."
    metaImage={ThumbnailImage}>
      <Header />
      <CaseStudy type={CASE_STUDY_TYPES.TDP} />
    </Layout>
  );
}

export default Index;