import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Lottie from "lottie-react";
import LoadingAnimation from '../../lottie-files/loading.json';
import TickAnimation from '../../lottie-files/tick.json';
import CloseAnimation from '../../lottie-files/close.json';
import {
  btn,
  btnSmall,
  btnPrimary
} from '../../ds/button.module.css';
import { WhiteArrowRight } from '../../ds/Shapes';
import { parseExternalLink } from '../../services/externalUploaderService';
import accessinfoImage from '../../images/anyonewithlink1.png';

type Props = {
  show: boolean;
  toggleDetailsComponent: (status: boolean) => void;
  toggleParseLinkComponent: (status: boolean) => void;
  trackEvent: (eventName: string) => void;
  addData: (key: string, value: string) => void;

}
export function ParseLink(props: Props) {

  const [isLoading, setLoading] = useState(false);
  const [isCheckDone, setCheck] = useState(false);
  const [showClose, setClose] = useState(false);
  const [url, setUrl] = useState('');
  const [errorMessageSize, setErrorMesssageSize] = useState('');
  const [errorMessageNumberofAssets, setErrorMesssageNumberofAssets] = useState('');
  const [code, setCode] = useState('');
  
  const messageSize = 'Size exceeds limit; Please ensure a maximum of 3GB';
  const messageNumberofAssets = 'Exceeding the limit, Please keep the photo count under 3000';
  const invalidUrlMessage = 'Invalid Drive link';

  const { show, toggleDetailsComponent, toggleParseLinkComponent, addData, trackEvent } = props; 

  if(!show) return null;

  function verifyGalleryLink (e) {

    setTimeout( () => {

      if(e.type === 'blur' && isLoading) {
        return;
      }

      setLoading(true);
      if(url === e.target.value) {
        setLoading(false);
        return;
      }
      setUrl(e.target.value);
      if(e.target.value.replace(' ','').length === 0) {
        setLoading(false);
        setCheck(false);
        setClose(false);
        setErrorMesssageSize('');
        setCode('');
        setErrorMesssageNumberofAssets('');
        return;
      }
      if(!e.target.value.includes('drive.google.com/drive/folders/')) {
        setLoading(false);
        setCode('');
        setCheck(false);
        setClose(true);
        setErrorMesssageSize(invalidUrlMessage);
        return;
      }
      setCheck(false);
      setLoading(true);
      setClose(false);
      setErrorMesssageSize('');
      setCode('');
      setErrorMesssageNumberofAssets('');
      parseExternalLink(e.target.value)
      .then((response) => {
          setLoading(false);
          if(response.success) {
            const { asset_count, size } = response.data;
            if(asset_count > 3000) setErrorMesssageSize(messageSize);
            if((size/1073741824) > 3 ) setErrorMesssageNumberofAssets(messageNumberofAssets);
            if(asset_count > 3000 || (size/1073741824) > 3) {
              setLoading(false);
              setClose(true);
              return;
            } 
            setCheck(true);
            addData('external_service_link', e.target.value);
            return;
          }
          setCheck(false);
          const {code, message} = response.error;
          setErrorMesssageSize(message);
          setCode(code);
      })
      .catch((data) => {
          setLoading(false);
          setErrorMesssageSize(invalidUrlMessage);
          setClose(true);
      });
    })
  }
  
  return (
    <div className='border shadow-lg p-10 rounded-2xl border-slate-100'>
      <div className='flex items-center'>
        <h4 className='font-semibold text-black'>Paste Your Drive Link</h4>
        <StaticImage src="../../images/gdrive.png" className='mx-1' width={16} alt='' />
      </div>
      <p className='text-sm text-black opacity-70 mt-5 text-sm text-zinc-600'>Enter the link to your Google Drive folder that has all your event photos. You can add up to 3000 photos & up to 3GB size in JPEG format.</p>
      <div className='flex items-center mt-5'>
        <input disabled={isLoading} placeholder='eg: https://drive.google.com/drive/.....' className='py-3 px-5  border rounded-lg focus:outline-none focus:border-black grow w-full' onPaste={verifyGalleryLink} onBlur={verifyGalleryLink} />
        <div style={{ width: '80px', height: '80px' }} className='flex justify-center'>
          {isLoading && <Lottie animationData={LoadingAnimation} style={{ width: '80px' }} />}
          {isCheckDone && <Lottie animationData={TickAnimation} loop={false} style={{ width: '32px' }} />}
          {showClose && <Lottie animationData={CloseAnimation} loop={false} style={{ width: '32px' }} />}
        </div>
      </div>
      <div className='flex items-center'>
        <p className='text-xs text-zinc-600'>supports</p>
        <StaticImage src="../../images/gdrive.png" className='mx-1' width={12} alt='' />
        <p className='text-xs text-zinc-600'>Google Drive</p>
        {/* <StaticImage src="../../images/dropbox.png" className='mx-1' width={14} alt='' />
        <p className='text-xs text-zinc-600'>Dropbox</p> */}
      </div>
      {errorMessageSize && <p className='text-red-600 text-sm pt-5'>{errorMessageSize}</p>}
      {errorMessageNumberofAssets && <p className='text-red-600 text-sm pt-5'>{errorMessageNumberofAssets}</p>}
      {
        code.toString() === '30003' &&
        <div className=''>
          <p className='text-sm text-black opacity-70 mt-5 text-sm text-zinc-600 mb-2'>Change <span className='text-black font-semibold'>General access</span> to <span className='text-black font-semibold'>Anyone with the link</span>.</p>
          <img src={accessinfoImage} className='h-28 border p-2' alt='' />
        </div>
      }
      <button id="parse_link_success" className={`${btn} ${btnPrimary} ${btnSmall} text-xs relative group mt-10`} disabled={!isCheckDone} onClick={() => {toggleParseLinkComponent(false); toggleDetailsComponent(true); trackEvent('continue')}}>
        <span className='mr-4 ml-2'>Continue</span>
        <WhiteArrowRight className='transition duration-300 ease-in-out group-hover:translate-x-2 mr-2' />
      </button>
    </div>
  )
}