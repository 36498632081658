import { StaticImage } from "gatsby-plugin-image";

import icon1 from '../../images/svg/1.svg';
import icon2 from '../../images/svg/2.svg';
import icon3 from '../../images/svg/3.svg';
import icon4 from '../../images/svg/4.svg';

const aioData =
    {
        color: '#FFF4E2',
        heroTitle: `Discover how Premagic Resolved DAM Challenges at AIOC Kochi`,
        heroImage: <StaticImage src="../../images/case-study/aio/1.png" alt="" />,
        metrics: {
            values: [
                {
                    label: 'Attendees',
                    value: '7,000',
                },
                {
                    label: 'Photos',
                    value: '29,500',
                },
                {
                    label: 'Photo Downloads',
                    value: '4,200',
                },
                {
                    label: 'Social Posts',
                    value: '120',
                }
            ],
            title: 'Background',
            content: `The All India Ophthalmological Society is an organisation of ophthalmologists which holds meetings every year. The society organised the 81st edition of the All India Ophthalmological Conference (AIOC), took place at Lulu Bolgatty International Convention Center in Kochi.`,
            footer: `The 4-day conference was managed by Impresario Event Management. Impresario is a distinguished event design and production company, boasting 27 years of expertise in crafting exceptional event experiences.`,
            host: 'All India Ophthalmological Society',
            organizer: 'Impresario Events',
            industry: 'Healthcare'
        },
        images: [
            {
                img: <StaticImage src="../../images/case-study/aio/2.png" alt="" />
            },
            {
                img: <StaticImage src="../../images/case-study/aio/3.png" alt="" />
            },
        ],
        gallery: <StaticImage src="../../images/case-study/aio/gallery.png" alt="" />,
        caseStudyData: [
            {
                title: 'The Challenge',
                subheading: 'Seeking a secure event photo management and storage solution.',
                info1: `As a prestigious society that organizes annual conferences in various regions of the country, AIOC aims to facilitate numerous scientific activities, including instructional courses, symposia, lectures, and surgical skill transfer courses. These events are conducted to promote and facilitate the exchange of knowledge within the field of ophthalmic science. <br /><br />With hundreds of attendees, multiple sessions, and various activities, managing event photos manually can be overwhelming and time-consuming. In the past editions of the event, the organizers had struggled with efficiently sorting, organizing, and distributing photos to participants.`,
                testimonial: {
                    img: <StaticImage src="../../images/case-study/aio/avatar.png" alt="" />,
                    message: `“ Impresario usually puts up the entire structure and plan, taking into consideration the suggestions put forward by the committee. A simple solution for photo management and delivery was one of their main requests “`,
                    name: 'Hareesh Babu',
                    info: `Managing Director,<br />Impresario Event Management`
                },
                info2: `In the past, they stuck with the old-school ways of sharing event photos, like email or Google Drive. But that often led to delays and confusion, making attendees wait longer for their pics. <br /><br />This conference is all about giving attendees an effortless and unforgettable experience. Quick and easy access to event photos is a big part of it, letting folks relive the good times and share their moments on social media.`
            },
            {
                title: 'The Solution',
                subheading: 'Exploring the potential of a versatile event solution platform',
                info1: `"We initially brought Premagic on board primarily for its AI photo distribution feature, but the advantages of this platform went beyond our expectations.”<br /><br />
                Besides providing a safe and secure digital asset management solution, Premagic offered the Impresario team another valuable feature – the in-gallery placement of sponsor brands.<br /><br />
                In many events, traditional sponsor branding on backdrops and invitations often results in limited engagement between the audience and sponsor brands. This innovative approach was a game-changer, instantly fostering positive brand associations," shared Hareesh.`
            },
            {
                title: 'The Results',
                subheading: 'Revolutionizing event excellence with novelty and efficiency',
                info1: `Premagic's AI-powered photo distribution played a pivotal role in elevating the in-person experience at AIOC 2023, ensuring it met its commitment to delivering a top-tier event for all attendees. Here's how Premagic contributed to achieving this objective:`,
                gridViewData: [{
                    icon: <img src={icon1} alt="" />,
                    title: 'Instant Photo Delivery',
                    description: `Premagic seamlessly delivered nearly 29,000 photos in real-time, ensuring attendees could access their event memories promptly.`
                },
                {
                    icon: <img src={icon2} alt="" />,
                    title: 'Secure Digital Asset Management',
                    description: `The platform offered a secure and efficient digital asset management solution, simplifying the organization and access to event photos.`

                },
                {
                    icon: <img src={icon3} alt="" />,
                    title: 'Innovative Sponsor Branding',
                    description: `Sponsors benefited from a novel approach to brand placement through in-gallery sponsorship, maximizing their brand exposure and engagement.`

                },
                {
                    icon: <img src={icon4} alt="" />,
                    title: 'A Novel Concept',
                    description: `Attendees were pleasantly surprised by this innovative service, which eliminated the need for them to take photos on their own devices during the busy event sessions, enhancing their overall experience.`

                }
                ]
            },
            {
                title: 'The Future',
                subheading: `Awaiting exciting new collaborations in future`,
                info1: `Due to the tremendous success of the entire experience, the Impresario team is excited to collaborate with Premagic again.`,
                testimonial: {
                    img: <StaticImage src="../../images/case-study/aio/avatar.png" alt="" />,
                    message: `“ Our team was genuinely impressed with the supportive and innovative nature of the Premagic members. They consistently demonstrated a keen willingness to assist and a remarkable ability to devise creative solutions to any challenges that arose during the event. “`,
                    name: 'Hareesh Babu',
                    info: `Managing Director,<br />Impresario Event Management`
                }, 
                info2: `This experience greatly bolstered their confidence in Premagic's capabilities and their dedication to delivering exceptional event experiences. They look forward to future collaborations and continuing their partnership with Premagic.`
            }
        ]
    }



export default aioData;