

export function WhatsAppIcon() {
    return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#a)">
        <path d="M.340731 7.90428C.340359 9.24859.691615 10.5612 1.35952 11.7182l-1.082665 3.953 4.045395-1.0608c1.1189.6092 2.37255.9283 3.64651.9284h.00335c4.20559 0 7.62899-3.4222 7.63079-7.62851.0008-2.03826-.7922-3.95491-2.2331-5.3969C11.9292 1.07153 10.0132.277053 7.9718.276123 3.76572.276123.34253 3.69814.340793 7.90428" fill="url(#b)"/>
        <path d="M.0683097 7.90177C.0678756 9.29445.431721 10.654 1.12344 11.8524L.00195312 15.9471 4.1924 14.8484c1.15461.6295 2.45458.9614 3.77737.9619h.00341c4.35642 0 7.90292-3.5453 7.90472-7.90221.0008-2.1115-.8208-4.09705-2.3132-5.59069C12.0722.823938 10.0877.00086822 7.97318 0 3.61597 0 .0700462 3.54481.0683097 7.90177ZM2.56381 11.646l-.15646-.2484c-.65774-1.0458-1.0049-2.25434-1.0044-3.49534.00142-3.62139 2.94865-6.56769 6.57271-6.56769 1.75504.00075 3.40444.6849 4.64494 1.92621 1.2405 1.24142 1.9231 2.89165 1.9227 4.64682-.0016 3.6214-2.9489 6.5681-6.57012 6.5681h-.00261c-1.1791-.0007-2.3355-.3173-3.344-.9157l-.24-.1423-2.48669.6519.66393-2.4236Z" fill="url(#c)"/>
        <path d="M5.99747 4.59831c-.14797-.32887-.30369-.33551-.4444-.34127-.11522-.00496-.24695-.00459-.37854-.00459-.13172 0-.34574.04955-.52664.24707-.18108.1977-.69135.67547-.69135 1.64719 0 .97178.70779 1.91089.80645 2.04279.09879.13166 1.36639 2.1896 3.37396 2.9813 1.66846.6579 2.00795.527 2.37015.4941.3621-.0329 1.1686-.4777 1.3331-.9389.1647-.4611.1647-.85644.1153-.93904-.0494-.0823-.1811-.13172-.3786-.23045-.1976-.09879-1.1686-.57662-1.3497-.64254-.181-.06586-.3127-.09873-.44442.09904-.13173.19745-.50996.64223-.62524.77395-.11517.13203-.23046.14846-.42791.04967-.19765-.0991-.83374-.30741-1.5884-.98021-.58717-.52354-.98357-1.17005-1.0988-1.36782-.11522-.19745-.01234-.30449.0867-.40291.08875-.0885.19758-.23064.29644-.34592.09848-.11535.13135-.19765.19721-.32937.06592-.13184.03293-.24719-.01638-.34598-.04948-.09879-.4333-1.0756-.60893-1.46611Z" fill="#fff"/>
    </g>
    <defs>
        <linearGradient id="b" x1="766.581" y1="1539.78" x2="766.581" y2=".276123" gradientUnits="userSpaceOnUse">
        <stop stop-color="#1FAF38"/>
        <stop offset="1" stop-color="#60D669"/>
        </linearGradient>
        <linearGradient id="c" x1="793.8" y1="1594.71" x2="793.8" y2="0" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F9F9F9"/>
        <stop offset="1" stop-color="#fff"/>
        </linearGradient>
        <clipPath id="a">
        <path fill="#fff" d="M0 0h15.88v16H0z"/>
        </clipPath>
    </defs>
    </svg>
    );
  }

  export function CheckIconPink() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
            <circle cx="13" cy="13" r="13" fill="#FFE8ED"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9885 9.09985L17.8797 9.84836L11.3053 16.3244L7.80029 12.5233L9.46384 12.5231L11.328 14.6367L16.9885 9.09985ZM17.0151 9.80125L11.3002 15.3913L9.22914 13.0432L8.987 13.0432L11.3233 15.5768L17.1076 9.87898L17.0151 9.80125Z" fill="#D5123B" stroke="#D4123B" stroke-width="0.3"/>
        </svg>
    )
  }

  export function CheckIconGreen() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
            <circle cx="13" cy="13" r="13" fill="#61F2C2"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9885 9.09985L17.8797 9.84836L11.3053 16.3244L7.80029 12.5233L9.46384 12.5231L11.328 14.6367L16.9885 9.09985ZM17.0151 9.80125L11.3002 15.3913L9.22914 13.0432L8.987 13.0432L11.3233 15.5768L17.1076 9.87898L17.0151 9.80125Z" fill="#fff" stroke="#fff" stroke-width="1"/>
        </svg>
    )
  }
  
  export function CheckIconViolet() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
            <circle cx="13" cy="13" r="13" fill="#F0F3FF"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9885 9.09985L17.8797 9.84836L11.3053 16.3244L7.80029 12.5233L9.46384 12.5231L11.328 14.6367L16.9885 9.09985ZM17.0151 9.80125L11.3002 15.3913L9.22914 13.0432L8.987 13.0432L11.3233 15.5768L17.1076 9.87898L17.0151 9.80125Z" fill="#1439CE" stroke="#1439CE" stroke-width="0.3"/>
        </svg>
    )
  }
  export function CheckIconBrown() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
            <circle cx="13" cy="13" r="13" fill="#FFF2E0"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9885 9.09985L17.8797 9.84836L11.3053 16.3244L7.80029 12.5233L9.46384 12.5231L11.328 14.6367L16.9885 9.09985ZM17.0151 9.80125L11.3002 15.3913L9.22914 13.0432L8.987 13.0432L11.3233 15.5768L17.1076 9.87898L17.0151 9.80125Z" fill="#A05D00" stroke="#A05D00" stroke-width="0.3"/>
        </svg>
    )
  }
  export function CheckIconPurple() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
            <circle cx="13" cy="13" r="13" fill="#F3EBFF"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9885 9.09985L17.8797 9.84836L11.3053 16.3244L7.80029 12.5233L9.46384 12.5231L11.328 14.6367L16.9885 9.09985ZM17.0151 9.80125L11.3002 15.3913L9.22914 13.0432L8.987 13.0432L11.3233 15.5768L17.1076 9.87898L17.0151 9.80125Z" fill="#3C0097" stroke="#3C0097" stroke-width="0.3"/>
        </svg>
    )
  }

  export function Calendar() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="26" viewBox="0 0 24 26" fill="none">
            <path d="M21.3882 3.05312H17.8441V0.739285C17.8441 0.331084 17.5098 0 17.0981 0C16.6861 0 16.352 0.331355 16.352 0.739285V3.05312H7.64759V0.739285C7.64759 0.331084 7.31319 0 6.90152 0C6.48958 0 6.15546 0.331355 6.15546 0.739285V3.05312H2.6114C1.17183 3.05312 0 4.21404 0 5.64079V22.8241C0 24.2511 1.17183 25.4118 2.6114 25.4118H21.3886C22.8282 25.4118 24 24.2509 24 22.8241L23.9997 5.64079C23.9997 4.21377 22.8278 3.05312 21.3882 3.05312ZM22.5075 22.8242C22.5075 23.4356 22.0052 23.9333 21.3882 23.9333H2.611C1.99399 23.9333 1.49173 23.4356 1.49173 22.8242V12.5762H22.5073L22.5074 17.7002L22.5075 22.8242ZM22.5075 11.0975H1.49186V5.64033C1.49186 5.02893 1.99411 4.53123 2.61113 4.53123H6.15519V7.02324C6.15519 7.43144 6.48958 7.76253 6.90125 7.76253C7.3132 7.76253 7.64732 7.43117 7.64732 7.02324V4.53123H16.3517V7.02324C16.3517 7.43144 16.6861 7.76253 17.0978 7.76253C17.5098 7.76253 17.8439 7.43117 17.8439 7.02324V4.53123H21.3879C22.0049 4.53123 22.5072 5.02892 22.5072 5.64033L22.5075 11.0975Z" fill="black"/>
        </svg>
    )
  }

  export function GreenCapsule() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="23" viewBox="0 0 15 23" fill="none">
            <path d="M11.2922 1.20089C10.7168 0.915557 10.0845 0.76327 9.44232 0.755306C8.56605 0.78775 7.71502 1.05693 6.97972 1.53423C6.24442 2.01153 5.65233 2.67911 5.26641 3.46599L0.347255 16.1692C0.134766 16.7116 0.0353446 17.2917 0.0550799 17.8739C0.0748161 18.4561 0.213295 19.0282 0.462031 19.5552C0.710766 20.0821 1.06452 20.5527 1.50161 20.9382C1.9387 21.3237 2.44993 21.616 3.00396 21.7971C3.55187 22.0402 4.1439 22.1683 4.74333 22.1734C5.34275 22.1784 5.93678 22.0604 6.4886 21.8265C7.04041 21.5926 7.53823 21.2479 7.9512 20.8137C8.36416 20.3795 8.68346 19.8652 8.88929 19.3025L13.8787 6.71405C14.0746 6.18268 14.1631 5.61769 14.1389 5.05184C14.1146 4.48599 13.9783 3.93054 13.7377 3.4177C13.4971 2.90486 13.1571 2.44484 12.7373 2.0643C12.3175 1.68376 11.8263 1.39029 11.2922 1.20089Z" fill="#61F2C2"/>
        </svg>
    )
  }

  export function OrangeCapsule() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="28" viewBox="0 0 18 28" fill="none">
            <path d="M14.2775 1.47828C13.5636 1.12428 12.7792 0.935335 11.9824 0.925453C10.8952 0.965705 9.83934 1.29968 8.92705 1.89186C8.01477 2.48405 7.28017 3.31231 6.80136 4.28858L0.698199 20.0493C0.434566 20.7223 0.311214 21.442 0.3357 22.1644C0.360186 22.8868 0.531996 23.5966 0.8406 24.2503C1.14921 24.904 1.5881 25.4879 2.1304 25.9662C2.6727 26.4445 3.30697 26.8071 3.99436 27.0318C4.67415 27.3335 5.40868 27.4924 6.15238 27.4987C6.89607 27.505 7.63309 27.3585 8.31772 27.0683C9.00236 26.7781 9.62 26.3504 10.1324 25.8118C10.6447 25.2731 11.0409 24.6349 11.2963 23.9369L17.4866 8.31843C17.7296 7.65916 17.8394 6.95818 17.8093 6.25613C17.7793 5.55409 17.6101 4.86495 17.3116 4.22867C17.0131 3.59239 16.5912 3.02164 16.0704 2.54951C15.5496 2.07738 14.9402 1.71327 14.2775 1.47828Z" fill="#FF6550"/>
        </svg>
    )
  }

  export function UploadIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
            <path d="M5 20.3028C3.49252 19.2937 2.5 17.5753 2.5 15.625C2.5 12.6955 4.73939 10.2891 7.59968 10.0242C8.18477 6.46517 11.2753 3.75 15 3.75C18.7247 3.75 21.8152 6.46517 22.4003 10.0242C25.2606 10.2891 27.5 12.6955 27.5 15.625C27.5 17.5753 26.5075 19.2937 25 20.3028" stroke="#EBEBEB" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10 20L15 15M15 15L20 20M15 15V26.25" stroke="#EBEBEB" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
  }

  export function Camera() {
    return (
        <svg className="w-6" viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="M277.258 276.758c0-12.54 10.176-22.715 22.712-22.715s22.715 10.175 22.715 22.715c0 12.537-10.179 22.712-22.715 22.712s-22.712-10.175-22.712-22.712zm22.712-59.057c-32.615 0-59.056 26.441-59.056 59.057s26.44 59.052 59.056 59.052 59.057-26.436 59.057-59.052-26.442-59.057-59.057-59.057zM31.949 381.241c0 20.079 16.264 36.34 36.34 36.34H431.71c20.078 0 36.34-16.261 36.34-36.34V172.274c0-20.079-16.262-36.342-36.34-36.342h-34.07l-46.521-46.52c-5.356-5.358-12.36-7.993-19.348-7.993H268.26c-6.993 0-14.081 2.635-19.352 7.993l-46.342 46.52H168.23V115.49c0-8.811-7.08-15.9-15.901-15.9H93.274a15.856 15.856 0 0 0-15.899 15.9v20.442H68.29c-20.077 0-36.34 16.264-36.34 36.342v208.967zm118.112-163.54H95.548c-9.992 0-18.173-8.177-18.173-18.17 0-9.996 8.181-18.173 18.173-18.173h54.512c9.99 0 18.17 8.177 18.17 18.173.001 9.993-8.18 18.17-18.169 18.17zm149.909-36.343c52.693 0 95.396 42.705 95.396 95.4 0 52.69-42.703 95.397-95.396 95.397-52.694 0-95.396-42.707-95.396-95.397 0-52.695 42.702-95.4 95.396-95.4z" fill="#ffffff" fill-rule="evenodd" className="fill-010101"></path></svg>
    )
  }
  