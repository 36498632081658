
import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import {
  btn,
  btnDefault,
  playButton,
  playButtonIcon
} from '../../ds/button.module.css';
import { ArrowRightWithGradient } from '../../ds/Shapes';
import { cardBorderBottomBlack } from '../../ds/card.module.css';
import useWindowDimensions from '../../utils/useWindowDimensions';
import FeatureIcon1 from '../../images/svg/1.svg';
import FeatureIcon2 from '../../images/svg/2.svg';
import FeatureIcon3 from '../../images/svg/3.svg';

export function FeatureCards() {
    return (
      <div className='pm-container mx-auto px-6 my-10'>
        <div className='md:flex md:-mx-5'>
          <div className={`${cardBorderBottomBlack} rounded-4xl p-10 my-10 md:my-10 md:mx-5`}>
          <img
            src={FeatureIcon1}
            alt="Premagic"
            placeholder="blurred"
          />
            <h2 className='font-bold text-xl text-black mt-8'>Supercharge your <br />social media presence</h2>
            <p className='text-sm text-black opacity-50 mt-4'>Let your attendees become your brand advocates, sharing their epic experiences long after the curtains close. Extend the life of your events with user-generated content (UGC).</p>
          </div>
          <div className={`${cardBorderBottomBlack} rounded-4xl p-10 my-10 md:my-10 md:mx-5`}>
          <img
            src={FeatureIcon2}
            alt="Premagic"
            placeholder="blurred"
          />
            <h2 className='font-bold text-xl text-black mt-8'>Deliver consistent ROI <br />to sponsors</h2>
            <p className='text-sm text-black opacity-50 mt-4'>No more long and boring strategies. Offer your event sponsors clear-cut branding opportunities, customizations and thorough analytics to understand the event’s success.</p>
          </div>
          <div className={`${cardBorderBottomBlack} rounded-4xl p-10 my-10 md:my-10 md:mx-5`}>
          <img
            src={FeatureIcon3}
            alt="Premagic"
            placeholder="blurred"
          />
            <h2 className='font-bold text-xl text-black mt-8'>Instant photo sharing, <br />real-time connection</h2>
            <p className='text-sm text-black opacity-50 mt-4'>Personalized experiences. That's one way to reach peak engagement. With frictionless, real-time photo distribution across multiple platforms, take your attendee engagement to a whole new level.</p>
          </div>
        </div>

        
      </div>
    );
}

export default FeatureCards;