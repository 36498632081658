import React from 'react';

import Layout from '../components/Layout';
import Header from '../components/Header';
import { StaticImage } from 'gatsby-plugin-image';

export default function ContactUs() {
  const isBrowser = () => typeof window !== "undefined";

  const isUAEDomain = isBrowser() && window.location.host.includes('ae');
  return (
    <Layout title="Contact Premagic" description="Get in touch with Premagic">
      <Header />
      <section id="about" className="pm-container py-12">
        <h2 className="font-light text-4xl py-10">
          Contact <span className="font-bold">Us</span>
        </h2>
        <div className="">
          <ul>
            <li className="mb-2">
              {
                isUAEDomain ? 
                <a className="text-base font-bold" href="tel:+971529453727">
                  +971 52 945 3727
                </a> :
                <a className="text-base font-bold" href="tel:‭+919895096532">
                  +91 9895096532
                </a>
              }
            </li>
            <li className="mb-2">
              {
                isUAEDomain ?
                <a
                  className="text-base font-bold"
                  href="mailto:sales@premagic.ae?Subject=Enquiry"
                >
                  sales@premagic.ae
                </a> : 
                <a
                  className="text-base font-bold"
                  href="mailto:sales@premagic.com?Subject=Enquiry"
                >
                  sales@premagic.com
                </a> 
              }
            </li>
            <li className="mb-2">
              {
                isUAEDomain ?
                <a
                  className="text-base font-bold"
                  href="mailto:support@premagic.ae?Subject=Help"
                >
                  support@premagic.ae
                </a> :
                <a
                  className="text-base font-bold"
                  href="mailto:support@premagic.com?Subject=Help"
                >
                  support@premagic.com
                </a>
              }
            </li>
          </ul>
          <p className="text-base text-gray-900 pt-10">{`
            Our business timings are Monday - Friday (9AM to 5PM (${isUAEDomain ? 'GST':'IST'}) & 8AM to
            4PM (PST))`}
          </p>
        </div>
      </section>
    </Layout>
  );
}
