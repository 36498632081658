import { Link } from "gatsby";
import { btn, btnPrimary } from '../ds/button.module.css';
import { WhiteArrowRight } from "../ds/Shapes";
import { CheckIconGreen } from '../ds/SvgIcons';
import { StaticImage } from "gatsby-plugin-image";
import Header from "../components/Header";
import Layout from "../components/Layout";
import HowItWorks from '../components/ai-distribution/HowItWorks';
import CustomizableLandingPage from '../components/ai-distribution/CustomizableLandingPage';

export default function index() {
    return (
        <Layout title="Smart Photo Distribution for Seamless Events" description="Deliver AI-powered photo galleries straight to your attendees’ inbox!">
    <div className=''>
        <div className="" style={{ backgroundColor: '#7251F4' }}>
            <Header isWhiteLogo />
        </div>

        <section className="py-28" style={{ backgroundColor: '#7251F4' }}>
        <div className="px-5 md:px-0 mx-auto md:w-[1080px]">
            <div className="md:flex items-center h-[550px] md:h-[500px]">
                <div className="md:w-5/12 flex flex-col justify-center text-left">
                    <h1 className="text-3xl md:text-5xl font-bold mb-4 text-white">Smart Photo Distribution<span style={{ color: '#61F2C2' }}> for Seamless Events</span> <br /></h1>
                    <p className="text-white mb-5">Deliver<span style={{ color: '#61F2C2' }}> AI-powered photo galleries</span> straight to your attendee's inbox! </p>
                    <Link to="/request-a-demo/" className={`${btn} ${btnPrimary} text-xs mt-5 mb-10 md:mb-0 relative w-max group`}>
                    <span className='mr-4 ml-2'>Show me how</span>
                    <WhiteArrowRight className='transition duration-300 ease-in-out group-hover:translate-x-2 mr-2' />
                    </Link>
                </div>
                <div className="w-5/6 md:w-7/12 md:ml-0 relative">
                    <div className="">
                        <StaticImage src="../images/ai-distribution_hero.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
        </section>  
        <section className="md:pt-10">
        <div className="px-5 md:px-0 mx-auto md:w-[1080px]">
            <div className="flex flex-col md:flex-row flex-col-reverse py-10">
                <div className="md:w-5/12 flex flex-col justify-center text-left md:pr-10 md:mt-0">
                    <h1 className="text-3xl md:text-4xl font-bold md:leading-snug mb-4" style={{ color:'#7251F4' }}>Do more with your <br />event photos.</h1>
                    <p>Premagic simplifies event photo management by using facial recognition to create and deliver personalized galleries, straight to each attendee's phone.  Turn every shared photo into a marketing opportunity.</p>
                    <Link to="/request-a-demo/" className={`${btn} ${btnPrimary} text-xs mt-10 mb-10 md:mb-0 relative w-max group`}>
                    <span className='mr-4 ml-2'>Show me how</span>
                    <WhiteArrowRight className='transition duration-300 ease-in-out group-hover:translate-x-2 mr-2' />
                    </Link>
                </div>
                <div className="md:w-7/12 md:pl-10 text-right mt-10 md:mt-0 -mr-10 md:mr-0">
                    <StaticImage src="../images/ai_dist_how_it_works.png" alt="" />
                </div>
            </div>
        </div>
        </section>
       
        <div className="hidden md:block">
            <HowItWorks />
        </div>
        <CustomizableLandingPage />

        <section className='py-20 md:py-0' style={{ backgroundColor: '#7251F4' }}>
        <div className="px-5 md:px-0 mx-auto md:flex py-20 items-center md:w-[1080px]">
          <div className='md:w-1/2 flex flex-col justify-center relative mb-20 md:mb-0'>
            <div className="md:absolute">
                <StaticImage width={350} src="../images/etech_event.png" alt="" />
            </div>
            
          </div>
          <div className='md:w-1/2'>
            <h4 className="text-white text-center text-2xl"><span style={{ color: '#61F2C2' }}>“</span>Premagic really came through with solutions that not only saved us substantial time and effort but also felt like having an extra member on our team. We will definitely be using it for our future events.! 
            <span style={{ color: '#61F2C2' }}>”</span></h4>
            <h5 className="text-2xl text-center mt-10" style={{ color: '#61F2C2' }}>Malavika Velayanikal</h5>
            <p className="text-white text-xl text-center">Director of Initiatives & Events SaaSBOOMi</p>
          </div>
        </div>
        </section>
        
       
        <section className='my-20 mt-40'>
        <div className="px-5 md:px-0 mx-auto flex py-10 md:items-center flex-wrap md:w-[1080px]">
            <div className="w-1/2 mb-20 md:mb-0 md:w-1/4 text-center">
                <StaticImage class='w-20 md:w-18' src="../images/ai_dist_metric_1.png" alt="" />
                <h4 style={{ color: '#FF6550' }} className="text-4xl font-bold my-5">99.9%</h4>
                <p>facial recognition <br />
                accuracy</p>
            </div>
            <div className="w-1/2 mb-20 md:mb-0 md:w-1/4 text-center">
                <StaticImage class='w-20 md:w-20' src="../images/ai_dist_metric_2.png" alt="" />
                <h4 style={{ color: '#FF6550' }} className="text-4xl font-bold my-8 md:my-5 ">700K</h4>
                <p>events<br />
                powered</p>
            </div>
            <div className="w-1/2 md:w-1/4 text-center">
                <StaticImage class='w-20 md:w-20' src="../images/ai_dist_metric_3.png" alt="" />
                <h4 style={{ color: '#FF6550' }} className="text-4xl font-bold my-5">70%</h4>
                <p>increase in attendee<br />
                engagement</p>
            </div>
            <div className="w-1/2 md:w-1/4 text-center">
                <StaticImage class='w-20 md:w-20' src="../images/ai_dist_metric_4.png" alt="" />
                <h4 style={{ color: '#FF6550' }} className="text-4xl font-bold my-2 md:my-5">60%</h4>
                <p>increase in <br />
                UGC</p>
            </div>
        </div>
        </section>
       


        {/* <RequestDemo /> */}
       
    </div>      
    </Layout>
    )
}