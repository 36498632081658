export function getWindowObject(): any {
  if (typeof window === 'undefined') return {};
  return window;
}

function getQueryString() {
  if (typeof window === 'undefined') return '';
  return getWindowObject().location.search.substring(1);
}

export function getUserOSName() {
  const unknown = '-';

  const nAgt = navigator.userAgent;
  let os = unknown;
  const clientStrings = [
    { s: 'Android', r: /Android/ },
    { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
    { s: 'win', r: /(Windows)/ },
    {
      s: 'mac',
      r: /(Mac OS X| MacPPC|MacIntel|Mac_PowerPC|Macintosh)/,
    },
    { s: 'Linux', r: /(Linux|X11(?!.*CrOS))/ },
  ];

  for (const id in clientStrings) {
    const cs = clientStrings[id];
    if (cs.r.test(nAgt)) {
      os = cs.s;
      break;
    }
  }

  return os;
}

export function getAllQueryVariables() {
  let queryVariableObject = {};
  const query = getQueryString();
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    queryVariableObject[pair[0]] = pair[1];
  }

  return queryVariableObject;
}

export function getFormData(e): Record<string, any> {
  e.preventDefault();
  const formData = new FormData(e.currentTarget);
  return Array.from(formData.entries()).reduce(
    (memo, pair) => ({
      ...memo,
      [pair[0]]: pair[1],
    }),
    {}
  );
}

export function scrollToElement(
  element: string | HTMLElement | Element | null,
  smoothScroll?: boolean
): boolean {
  let $element;
  if (typeof element === 'string') {
    $element = document.querySelector(element);
  } else {
    $element = element;
  }

  if (!$element) return false;

  const offset = 32;
  const top = $element.getBoundingClientRect().top + window.scrollY - offset;

  if (smoothScroll) {
    window.scroll({
      top,
      behavior: 'smooth',
    });
    return true;
  }
  window.scroll(0, top);
  return true;
}

export function focusOnElement(element) {
  let $element;
  if (typeof element === 'string') {
    $element = document.querySelector(element);
  } else {
    $element = element;
  }

  if (!$element) return false;
  $element.focus();
  return true;
}

export function URLifyText(string: string) {
  return string.replace(/\s+/g, '-').toLowerCase();
}
