import { StaticImage } from "gatsby-plugin-image";

import icon1 from '../../images/svg/1.svg';
import icon2 from '../../images/svg/2.svg';
import icon3 from '../../images/svg/3.svg';
import icon4 from '../../images/svg/4.svg';

const wowAwardsData =
    {
        color: '#FFF4E4',
        heroTitle: `Find out how WOW<br /> Awards uses<br /> Premagic to wow<br /> their audience`,
        heroImage: <StaticImage src="../../images/case-study/wowawards/1.png" alt="" />,
        metrics: {
            values: [
                {
                    label: 'Attendees',
                    value: '6,200',
                },
                {
                    label: 'Photos',
                    value: '3,600',
                },
                {
                    label: 'Photo Downloads',
                    value: '1,700',
                },
                {
                    label: 'Social Posts',
                    value: '165',
                }
            ],
            title: 'Background',
            content: `WOW Awards Asia is the region's largest business & recognition platform for the Experiential Marketing, MICE, LIVE Entertainment and wedding Industry. Since 2009, WOW Awards has been the epitome of cultivating and celebrating excellence in the creation of LIVE events.`,
            footer: `With attendees ranging from event planners to industry professionals, it aimed to celebrate remarkable achievements purely on customer nominations.`,
            host: 'EEMA (Event and Entertainment Management Association)',
            organizer: 'EVENTFAQS Media',
            industry: 'Entertainment & Wellness'
        },
        images: [
            {
                img: <StaticImage src="../../images/case-study/wowawards/2.png" alt="" />
            },
            {
                img: <StaticImage src="../../images/case-study/wowawards/3.png" alt="" />
            },
        ],
        gallery: <StaticImage src="../../images/case-study/wowawards/gallery.png" alt="" />,
        caseStudyData: [
            {
                title: 'The Challenge',
                subheading: 'Expanding the horizons of a time-honoured award ceremony',
                info1: 'When you’re a mega event that celebrates everything events, you know you have got to bring your A-game. The WOW Awards has always been the cream of the crop known for its glitz, glamour, excellence, innovation, and pure celebration. And the team behind it were settling for nothing less than the absolute best for this prestigious event.',
                testimonial: {
                    img: <StaticImage src="../../images/case-study/wowawards/avatar.png" alt="" />,
                    message: `“ We were celebrating the remarkable culmination of WOW Awards Asia's inspiring 14-year journey. While WOW Awards 2022 saw over 3000 attendees, this year, we’re looking at a much higher number. “`,
                    name: 'Deepak Choudhary',
                    info: `Founder & MD-EVA Live, <br />EVENTFAQS Media, <br />WOW Awards`
                },
                info2: `They had received a whopping 2000+ entries from 400+ agencies prior to the event, They would be entered in five diverse categories of awards. One of the main focuses of the event team was ensuring that the winners could share their winning moments on social media instantly, thereby helping them garnering more publicity and global recognition. The event also drew industry heavyweights, including celebrities, influencers, and visionaries. Maximizing their exposure on social media was also crucial to continue attracting big names in the future. <br /><br />Supported by giants like Inventum Global, Rixos Premium Sea Gate, Abu Dhabi Convention Bureau, and more, Wow Awards was setting a global stage to showcase these esteemed brands. This could work wonders in elevating the event's credibility and also strengthen these partnerships.`,
            },
            {
                title: 'The Solution',
                subheading: 'Leveraging the power of instant delivery to create an impact',
                info1: `Having used Premagic before, albeit on a smaller scale, Deepak knew his way around its various features. Previous guests had raved about the AI photo delivery service, so there was no doubt it had to make a comeback this year.<br /><br />
                "The whole idea behind WOW events is to, well, wow our guests with the coolest tech and event innovations out there. We knew that Premagic was definitely among the top rung when it comes to the novelty factor.”<br /><br />
                Instantaneity was crucial. In a world where everything happens in an instant - photos, videos, TikToks, and reels, Premagic promised seamless delivery of the required content for the same <br /><br />
                However, content delivery was just one facet. Deepak's goal was to make every moment count by enhancing networking and connections. He was sure that Premagic would play a pivotal role in achieving this. The sponsors were thrilled at the sponsor branding options offering precise targeting and measurable outcomes. Premagic was poised to wow not only the audience but also sponsors and organizers alike.`,
            },
            {
                title: 'The Results',
                subheading: '',
                info1: `The WOW team had set an ambitious goal of 5000 attendees for the event, but to their astonishment, turnout far exceeded expectations, with more than 8000 enthusiastic attendees and a whopping 3500 registrations. <br /><br />
                Thanks to the magic touch of Premagic, Deepak orchestrated an event that he believes attendees won't be forgetting anytime soon. <br /><br />
                "Many attendees hadn't experienced an event of this magnitude before," Deepak shared. "When they stepped into this beautifully produced event, complete with cutting-edge technology, dazzling lights, electrifying music, and the whole package, it's safe to say they were absolutely blown away. We left a significant and lasting impression that elevated our brand to new heights. It was truly an unforgettable experience." <br /><br />
                With the help of Premagic, Wow's social media team took event content and breathed new life into it after the event. They used social media channels and email marketing to engage with different segments of the audience, ensuring that the event continued to resonate long after the closing party. <br /><br />
                In the immediate aftermath of the event, Deepak and his team had compelling evidence that Wow Awards 2023 was a resounding success:`,
                gridViewData: [{
                    icon: <img src={icon1} alt="" />,
                    title: 'Amplified digital footprint',
                    description: `Attendees enthusiastically shared over 500 event photos across various social media platforms, greatly amplifying Wow Awards' digital footprint and reaffirming the event's influential status.`
                },
                {
                    icon: <img src={icon2} alt="" />,
                    title: 'Elevated event satisfaction',
                    description: `Premagic's swift delivery of over 2,000 event photos significantly elevated attendee satisfaction and participation, transforming the event into an indelible and cherished memory.`

                },
                {
                    icon: <img src={icon3} alt="" />,
                    title: 'Solid post-event engagement',
                    description: `Seamless access to event photos for attendees, enhancing their post-event engagement and fostering a stronger connection to the event.`

                },
                {
                    icon: <img src={icon4} alt="" />,
                    title: 'Strong metrics',
                    description: `The numbers spoke for themselves: an impressive 587,343 impressions, 1687 photo downloads, and an impressive 160+ social media posts that collectively echoed the success and impact of Wow Awards 2023.`

                }
                ]
            },
            {
                title: 'The Future',
                subheading: `Unleashing event potential with Premagic's expertise`,
                info1: `Wow Awards is gearing up for an even more fabulous event in the coming year, with even more spectacular surprises, extraordinary achievements, and unforgettable moments in store for all attendees. The collaboration with Premagic has been nothing short of fantastic, and both parties are thrilled about the potential for mutual growth and success.`,
                testimonial: {
                    img: <StaticImage src="../../images/case-study/wowawards/avatar.png" alt="" />,
                    message: `“ We wholeheartedly see Premagic as a valuable platform for our upcoming events. Our past experience has been exceptional, and we're eager to continue this successful partnership into the future. “`,
                    name: 'Deepak Choudhary',
                    info: `Founder & MD-EVA Live, <br />EVENTFAQS Media, <br />WOW Awards`
                }, 
            }
        ]
    }



export default wowAwardsData;