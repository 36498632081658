import { StaticImage } from "gatsby-plugin-image";

import icon1 from '../../images/svg/1.svg';
import icon2 from '../../images/svg/2.svg';
import icon3 from '../../images/svg/3.svg';
import icon4 from '../../images/svg/4.svg';

const tdpData =
    {
        color: '#BCEF93',
        heroTitle: `Discover How Premagic Powered GTECH Kerala Marathon 2024 with AI-Driven Photo Distribution`,
        heroImage: <StaticImage src="../../images/case-study/gtech/1.png" alt="" />,
        metrics: {
            values: [
                {
                    label: 'Attendees',
                    value: '6009',
                },
                {
                    label: 'Photos',
                    value: '33216',
                },
                {
                    label: 'Photo Downloads',
                    value: '15145',
                },
                {
                    label: 'Social Posts',
                    value: '1881',
                }
            ],
            title: 'Background',
            content: `GTECH (Group of Technology Companies) organized the most high-profile marathon, the GTECH Kerala Marathon 2024  in Kochi on February 11th, 2024. The fitness challenge saw runners from all walks of life participating. The primary purpose of the marathon was to raise public awareness about drug abuse prevalence in Kerala and its ill effects, especially among the youth.`,
            footer: ``,
            host: 'GTECH',
            organizer: 'GTECH',
            industry: 'IT' 
        },
        images: [
            {
                img: <StaticImage src="../../images/case-study/gtech/2.png" alt="" />
            },
            {
                img: <StaticImage src="../../images/case-study/gtech/3.png" alt="" />
            },
        ],
        gallery: <StaticImage src="../../images/case-study/gtech/gallery.png" alt="" />,
        caseStudyData: [
            {
                title: 'The Challenge',
                subheading: `Seamless photo distribution to all runners and easy access to key officials' photos.`,
                info1: `As a large-scale event with thousands of participants, GTECH faced significant challenges in efficiently distributing event photos to all runners. In past events, the manual process of sorting, organizing, and sharing photos with attendees proved cumbersome and time-consuming. Additionally, finding specific photos of key officials and VIPs amidst the vast pool of images was a daunting task.`,
                info2: `"Ensuring that every participant could easily access and share their marathon moments was crucial for creating a memorable experience," said Rony Sebastian, the organizer from GTECH. "We needed a solution that could streamline the photo distribution process while also providing a way to quickly locate specific photos of our key officials."`,
            },
            {
                title: 'The Solution',
                subheading: `Leveraging Premagic's AI-powered photo distribution and watermarking capabilities.`,
                info1: `To address these challenges, GTECH turned to Premagic, an AI-driven photo management platform. Premagic's AI-powered photo distribution feature, combined with watermarking capabilities, provided the perfect solution for seamless photo delivery and branding.<br /><br />
                Rony Sebastian explained, "Premagic's AI-powered photo distribution was a game-changer when it comes to organic marketing. It allowed us to effortlessly deliver event photos to all participants, while the watermarking feature helped us reinforce our brand presence on shared images."<br /><br />`,
            },
            {
                title: 'The Results',
                subheading: `Enhancing engagement and brand visibility through organic event marketing`,
                info1: `Premagic's AI-powered photo distribution transformed the runner experience at the GTECH Kerala Marathon 2024 into an exercise in organic event marketing. By seamlessly delivering personalized photo memories and empowering attendees to share their branded visuals, Premagic amplified GTECH's brand visibility exponentially.`,
                gridViewData: [{
                    icon: <img src={icon1} alt="" />,
                    title: ' Instant photo delivery',
                    description: `Premagic seamlessly delivered 33,216 photos to participants in real-time, ensuring they could access and share their marathon memories instantly.`
                },
                {
                    icon: <img src={icon2} alt="" />,
                    title: 'Efficient photo management',
                    description: `Premagic's AI-powered facial recognition capabilities made it easy to locate specific photos of key officials and VIPs, streamlining the photo management process.`

                },
                {
                    icon: <img src={icon3} alt="" />,
                    title: 'Increased social media engagement through Organic Marketing',
                    description: `With instant access to their photos, runners enthusiastically shared their marathon experiences on social media platforms. This user-generated content acted as organic event marketing, generating an estimated 17,026 posts and amplifying the event's visibility organically.`

                },
                {
                    icon: <img src={icon4} alt="" />,
                    title: 'Strengthened brand presence by converting Attendees into Event Marketers',
                    description: `The watermarking feature reinforced GTECH's brand presence on shared photos, effectively converting attendees into brand advocates and event marketers maximizing brand exposure and recognition through their social networks.`

                }
                ]
            },
            {
                title: 'The Future',
                subheading: `Embracing AI-driven solutions for future events`,
                info1: `Impressed by the success of Premagic's AI-powered photo distribution, GTECH is keen to continue their partnership for future events. Rony Sebastian expressed his enthusiasm, stating, "Premagic's AI-driven solutions have revolutionized our approach to photo management, distribution and even our organic event marketing strategies. We look forward to exploring more innovative features as we plan for the next edition of the Kerala Marathon and other upcoming events."`,
            }
        ]
    }



export default tdpData;