
import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import {
  btn,
  btnPrimary
} from '../../ds/button.module.css';
import { WhiteArrowRight } from '../../ds/Shapes';
import useWindowDimensions from '../../utils/useWindowDimensions';
import { Camera, GreenCapsule } from '../../ds/SvgIcons';
import { OrangeCapsule } from '../../ds/SvgIcons';
import { animationBounceLeft } from '../../ds/animation.module.css';
import StarRating from '../../images/svgs/stars.svg';
import G2Icon from '../../images/svgs/g2icon.svg';

export function Hero() {
  const { width } = useWindowDimensions();
  const isMobileUp = width >= 768;
  const heroBgHeight = isMobileUp ? '90vh' : '88vh';
  const isBrowser = () => typeof window !== "undefined";

  const isUAEDomain = isBrowser() && window.location.host.includes('ae');
    return (
      <div>
        {
          !isUAEDomain &&
          <a href='https://studio.premagic.com/'>
            <div className='fixed right-2 bottom-2 px-3 rounded-lg py-2 z-50 flex items-center' style={{ backgroundColor: '#FF6550' }}>
              <Camera />
              <p className='font-semibold text-white text-sm ml-1'>For Photographers</p>
            </div>
          </a>
        }
      <div className='absolute left-0 right-0 top-0 z-10 rounded-b-3xl' style={{ height: heroBgHeight, backgroundColor: '#7251F4', maxHeight: '750px' }}></div>
      <div className='container mx-auto px-6 mt-2 relative z-20'>
        <div className='relative rounded-lg pb-4'>
          
          <div className='relative text-center p-22 pb-8'>
              <h1 className='hidden md:block font-bold text-5xl text-white mt-10 leading-snug'>Boost your event marketing by 200%<br /> with our AI-driven strategies
              </h1>
              <h1 className='md:hidden font-bold text-3xl text-white mt-10 leading-snug'>Boost your event marketing by 200%<br /> with our AI-driven strategies
              </h1>
            <p className='my-16 md:my-8 text-white'>Empowering event professionals with AI-enabled photo distribution</p>
            <Link to="/request-a-demo/" className={`${btn} ${btnPrimary} text-xs relative w-max group`}>
              <span className='mr-4 ml-2'>Get a Demo</span>
              <WhiteArrowRight className={` ${animationBounceLeft} transition duration-300 ease-in-out group-hover:translate-x-2 mr-2`} />
            </Link>
          </div>
          <div className='flex justify-center items-center mb-10'>
            <img src={G2Icon} className='mt-1' />
            <img className='ml-3' src={StarRating} />
            <p className='text-white ml-2 mt-1 text-sm'>4.8 ratings</p>
          </div>
          <div className='flex justify-center'>
            <StaticImage
              className='mx-auto'
              style={{ width: '1080px' }}
              placeholder="blurred"
              src="../../images/events_hero.png"
              alt="logo"
            />
          </div>
          <div className='absolute top-36 md:top-28 left-5 md:left-28'>
            <StaticImage className='w-24 md:w-32 -ml-12 mb-16 animate-scale3' src='../../images/cloud_01.png' alt='' />
            <GreenCapsule />
          </div> 
          <div className='absolute top-40 md:top-32 -right-10 md:right-20'>
            <OrangeCapsule />
            <StaticImage className='w-24 md:w-32 mt-16 -ml-8 animate-scale1' src='../../images/cloud_02.png' alt='' />
          </div> 
        </div>
      </div>
      </div>
    );
}

export default Hero;