import React from 'react';
import Header from '../../../components/Header';
import Hero from '../../../components/inner-page/Hero';
import Layout from '../../../components/Layout';
import FeatureCards from '../../../components/inner-page/FeatureCards';
import MainFeaturesSection from '../../../components/inner-page/MainFeaturesSection';
import EventTypes from '../../../components/inner-page/EventTypes';
import CustomerFeedback from '../../../components/inner-page/CustomerFeedback';
import MagicWithUs from '../../../components/inner-page/MagicWithUs';

export function Index() {
  return (
    <Layout title="Premagic for corporates events" description='Master the Art of Hosting Impactful Corporate Events'>
      <Header />
      <Hero />
      <FeatureCards />
      <MainFeaturesSection />
      <EventTypes />
      <CustomerFeedback />
      <MagicWithUs />
    </Layout>
  );
}

export default Index;
