import React from 'react';
import Header from '../../components/Header';
import Layout from '../../components/Layout';

import RequestDemo from '../../components/RequestDemo';
import MagicWithUs from '../../components/inner-page/MagicWithUs';

export function Index() {
  return (
    <Layout title="Request for demo">
      <Header />
      <RequestDemo />
      <MagicWithUs />
    </Layout>
  );
}

export default Index;
