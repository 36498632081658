import useWindowDimensions from '../../../utils/useWindowDimensions';
import { StaticImage } from 'gatsby-plugin-image';
import LinkedInIcon from '../../../images/case-study/ugc/linkedin.svg';

const UGCData = [
  {
    image: <StaticImage src='../../../images/case-study/ugc/1.png' alt="" className="mb-5" />,
    content: `At <span class="text-blue-700 font-semibold">SaaSBOOMi</span> We created “Pathbreakers” award category for <span class="text-blue-700 font-semibold">Zoho</span> achieving $1B SaaS revenue and <span class="text-blue-700 font-semibold">Freshworks</span> for a spectacular NASDAQ IPO both these brands put India SaaS into global map and it extra proud moment personally for me as both these brands are from Chennai, Tamilnadu.`,
    avatar : <StaticImage src='../../../images/case-study/ugc/avatar.png' alt="" />,
    name: 'Suresh Sambandam',
    designation: 'Founder & CEO at Kissflow',
    logo: <img src={LinkedInIcon} alt="" />,
    classNames: 'pb-4'
  },
  {
    content: `Super pumped to announce that Sprinto won the Devtools Startup of the year award at <span class="text-blue-700 font-semibold">SaaSBOOMi</span>, Asia’s Biggest SaaS Conference 🏆
    Deeply indebted to our customers and partners, who supported and encouraged us.`,
    avatar : <StaticImage src='../../../images/case-study/ugc/avatar2.png' alt="" />,
    name: 'Sprinto',
    designation: 'Making compliance faster & easier',
    logo: <img src={LinkedInIcon} alt="" />,
    classNames: 'pb-4'
  },
  {
    content: `🎉 Just got back from <span class="text-blue-700 font-semibold">SaaSBOOMi</span> Annual in Chennai and it was 🔥! So many amazing startups showcasing their innovative solutions in the Saas space. 💡I am beyond intrigued by the potential of Saas startups in India and can't wait to see what the future holds. 🚀 It was a blast.`,
    avatar : <StaticImage src='../../../images/case-study/ugc/avatar3.png' alt="" />,
    name: 'Prashanth Ganesh',
    designation: 'Founder at PreSkale',
    logo: <img src={LinkedInIcon} alt="" />,
    classNames: 'pb-5'
  },
  {
    content: `I've been searching for inspiration to kickstart my third chapter at Hubilo, and (of course), it was an event that again came to my rescue. I attended the <span class="text-blue-700 font-semibold">SaaSBOOMi's</span> annual event last weekend at Chennai, where more than 1000+ SaaS founders came together to network and exchange information. I wasn't sure how the event would go nor the impact it would have, but I walked away super inspired by the kind of work that’s happening within the SaaS space in India. The quality and maturity of the sessions was at par or better with events that I have attended globally.`,
    avatar : <StaticImage src='../../../images/case-study/ugc/avatar4.png' alt="" />,
    name: 'Vaibhav Jain',
    designation: 'Founder & CEO at Hubilo',
    logo: <img src={LinkedInIcon} alt="" />,
    classNames: 'pb-5'
  },
  {
    content: `<span class="text-blue-700 font-semibold">#SaasBoomi</span> annual has always been a fixture in my calendar since I started <span class="text-blue-700 font-semibold">Wingman</span> by ClariFrom learning during sessions to finding my mentors and co-warriors - this community has given me so much.`,
    avatar : <StaticImage src='../../../images/case-study/ugc/avatar5.png' alt="" />,
    name: 'Shruti Kapoor',
    designation: 'Head of International Business',
    logo: <img src={LinkedInIcon} alt="" />,
    classNames: ''
  },
  {
    image: <StaticImage src='../../../images/case-study/ugc/2.png' alt="" className="mb-5" />,
    content: `We had an amazing time attending Saasboomi Annual 2023, one of the largest events for Saas founders in India!The opportunity to connect with industry leaders and like-minded individuals was invaluable.We came away with many great takeaways and insights that we can't wait to implement.Thanks to <span class="text-blue-700 font-semibold">SaaSBOOMi</span> for organizing such a fantastic event!`,
    avatar : <StaticImage src='../../../images/case-study/ugc/avatar5.png' alt="" />,
    name: 'Shruti Kapoor',
    designation: 'Head of International Business',
    logo: <img src={LinkedInIcon} alt="" />,
    classNames: ''
  }
]

export default function UGC() {
  const { width } = useWindowDimensions();
  const isMobileUp = width >= 1080;
  const containerWidth = isMobileUp ? '1080px' : '100%';

  return (
    <section className="py-24 relative z-20" style={{ backgroundColor: '#F1EDFE' }}>
      <div className="px-5 md:px-0 mx-auto" style={{ width: containerWidth }}>
        <p className='text-center text-blue-600 text-lg font-semibold'>#SaaSBoomiUGCHub</p>
        <h2 className='text-center text-black text-5xl font-bold leading-snug my-3'>Empowering Attendees to <br />Amplify Your Event</h2>
        <p className='text-center'>Transform your event photo dump into engaging user-generated content on social media!</p>
        <div className='md:flex md:flex-col md:flex-wrap mt-20' style={{ height: isMobileUp ? '795px' : 'auto'}}>
          {
            UGCData.map(item => (
              <div className='md:w-1/3 p-3'>
                <div className='bg-white p-5 rounded-xl'>
                  {item?.image}
                  <p className={`${item.classNames} text-sm`} dangerouslySetInnerHTML={{ __html: item.content}}></p>
                  <div className="flex rounded-2xl mt-5 items-center">
                    <div className="w-12 overflow-hidden rounded-full">{item.avatar}</div>
                    <div className="ml-2 flex flex-col justify-center">
                      <h5 className="font-bold text-sm text-black text-left">{item.name}</h5>
                      <p className="text-xs font-normal text-black text-left">{item.designation}</p>
                    </div>
                    <div className='ml-auto'>{item.logo}</div>
                  </div>
                </div>
              </div>
            ))

          }
        </div>
      </div>
    </section>
  )
}