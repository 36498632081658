
import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import {
  btn,
  btnDefault,
  playButton,
  playButtonIcon,
  btnPrimary
} from '../../ds/button.module.css';
import { ArrowRightWithGradient, WhiteArrowRight } from '../../ds/Shapes';
import useWindowDimensions from '../../utils/useWindowDimensions';
import HeroVideoDesktop from '../../images/events_hero.gif';
import HeroVideoMobile from '../../images/events_hero_mobile.mp4';

export function HeroSecondary() {
  const { width } = useWindowDimensions();
  const isMobileUp = width >= 768;
  if(isMobileUp) {
    return (
      <div className='pm-container mx-auto px-6 mt-20 mb-32'>
        <div className='flex'>
          <div className='w-1/2 flex flex-col'>
            <h4 className='px-5 py-3  bg-indigo-50 text-sm w-fit rounded-lg text-black'>built for / <strong>Corporates</strong></h4>
            <h1 className='font-bold text-5xl mt-10 leading-tight text-black'>Generate qualified<br /> leads from your<br /> marketing events<br /> with Premagic</h1>  
            <Link to='https://premagic.com' className={`${btn} ${btnPrimary} group text-xs mt-auto w-max`}>
              <span className='mr-2'>Dig deep</span>
              <WhiteArrowRight className='transition duration-300 ease-in-out group-hover:translate-x-2' />
            </Link>
          </div>
          <div className='w-1/2'>
              <StaticImage
                placeholder="blurred"
                src="../../images/usecasehero.png"
                alt="logo"
                className='w-full'
                height={517}
              />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className='pm-container mx-auto px-6 mt-20'>
      <h4 className='px-5 py-3  bg-indigo-50 text-sm w-fit mx-auto rounded-lg text-black'>built for / <strong>Corporates</strong></h4>
      <h1 className='text-center font-bold text-3xl mt-10 leading-tight text-black'>Host Engaging Corporate Events with Premagic Solutions</h1>  
      <div className='rounded-3xl overflow-hidden mt-14 shadow-xl'>
        <StaticImage
          placeholder="blurred"
          src="../../images/forevents.png"
          alt="logo"
          className='w-full'
          height={517}
        />
        <div className='p-12'>
          <h4 className='text-black text-center'>Trusted by Global Brands like</h4>
          <div className='mt-5 flex flex-wrap items-center justify-center'>
            <StaticImage
                className="w-16 m-3"
                src="../../images/client-logos/5.png"
                alt="weva"
                objectFit="contain"
              />
            <StaticImage
                className="w-20 m-3"
                src="../../images/client-logos/9.png"
                alt="weva"
                objectFit="contain"
              />
            <StaticImage
                className="w-36 m-3"
                src="../../images/client-logos/17.png"
                alt="weva"
                objectFit="contain"
              />
            <StaticImage
                className="w-28 m-3"
                src="../../images/client-logos/18.png"
                alt="weva"
                objectFit="contain"
              />
            <StaticImage
                className="w-28 m-3"
                src="../../images/client-logos/20.png"
                alt="weva"
                objectFit="contain"
              />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroSecondary;