import { StaticImage } from "gatsby-plugin-image";

import icon1 from '../../images/svg/1.svg';
import icon2 from '../../images/svg/2.svg';
import icon3 from '../../images/svg/3.svg';
import icon4 from '../../images/svg/4.svg';

const jsconfData =
    {
        color: '#FFE4E0',
        heroTitle: `Learn how Premagic optimized the attendee experience at JSConf India`,
        heroImage: <StaticImage src="../../images/case-study/jsconf/1.png" alt="" />,
        metrics: {
            values: [
                {
                    label: 'Attendees',
                    value: '1,500',
                },
                {
                    label: 'Photos',
                    value: '300',
                },
                {
                    label: 'Photo Downloads',
                    value: '100',
                },
                {
                    label: 'Social Posts',
                    value: '260',
                }
            ],
            title: 'Background',
            content: `Java Script Conference or JSConf India is the foremost gathering for JavaScript enthusiasts in India, representing a professional and non-profit conference organized by the Indian JavaScript community. In its inaugural in-person edition in 2023, the platform offered a unique opportunity for tech enthusiasts to connect, share ideas, and delve into discussions about JavaScript and its broader implications.`,
            footer: `JSConf India is a subsidiary of JSConf, a unique conference organisation fueled by passionate individuals dedicated to the tech community.`,
            host: 'JSConf India',
            organizer: 'JSConf India',
            industry: 'Information Technology'
        },
        images: [
            {
                img: <StaticImage src="../../images/case-study/jsconf/2.png" alt="" />
            },
            {
                img: <StaticImage src="../../images/case-study/jsconf/3.png" alt="" />
            },
        ],
        gallery: <StaticImage src="../../images/case-study/jsconf/gallery.png" alt="" />,
        caseStudyData: [
            {
                title: 'The Challenge',
                subheading: 'Navigating the in-person event scene',
                info1: `Transitioning from virtual to in-person, the dynamic team behind JSConf was determined to deliver an outstanding experience for their attendees. With this being the inaugural in-person edition of JSConf following years of virtual events. <br /><br />Sahil Mhapsekar, a key organizer, emphasised the significance:`,
                testimonial: {
                    img: <StaticImage src="../../images/case-study/jsconf/avatar.png" alt="" />,
                    message: `“ Hosting an in-person event for JSConf was a major milestone, especially as representatives of a global community. We were committed to doing justice to the JSConf community. “`,
                    name: 'Sahil Mhapsekar',
                    info: `Organizer,<br />JSConf India`
                },
                info2: `Sahil and his team embarked on a quest for a digital toolset that could elevate the event experience, facilitate ongoing learning, boost engagement, and provide effective event marketing.`
            },
            {
                title: 'The Solution',
                subheading: 'Creating connections through digital tools',
                info1: `The team recognized that the right event tech could enhance attendee engagement and improve the overall in-person event experience. Their main goal was to find a platform that could promote the event and its social presence while empowering attendees to create cost-effective and authentic content.<br /><br />
                The team wanted a way to generate excitement before and after the event to reach a broader community audience.<br /><br />
                Thanks to Premagic, Sahil could effortlessly create an event website without the need for coding skills. This user-friendly platform allowed attendees to access all relevant information about the conference and register, making it simple for Sahil to gather necessary data and gauge the total number of attendees.<br /><br />
                By providing attendees with the right content and tools, they aimed to facilitate easier engagement on social media. Premagic emerged as the ideal tool for distributing content to attendees and stimulating user-generated content (UGC).<br /><br />
                Moreover, Premagic's analytics would help them assess the event's effectiveness and provide insights for future endeavours.`
            },
            {
                title: 'The Results',
                subheading: 'Together, JSConf India and Premagic worked seamlessly to create a remarkable event experience for attendees. Here is how we pulled it off.',
                info1: ``,
                gridViewData: [{
                    icon: <img src={icon1} alt="" />,
                    title: 'Instant Photo Sharing',
                    description: `With real-time photo distribution, attendees shared memories instantly, making the event even more impactful.`
                },
                {
                    icon: <img src={icon2} alt="" />,
                    title: 'Making UGC easier',
                    description: `Seamlessly streamlining content to inspire more user-generated content and foster attendee engagement.`

                },
                {
                    icon: <img src={icon3} alt="" />,
                    title: 'Data-driven insights',
                    description: `Leveraging comprehensive data insights to fine-tune event performance and achieve better results.`

                },
                {
                    icon: <img src={icon4} alt="" />,
                    title: 'Effortless Event Registration',
                    description: `Streamline registrations with a personalized event website created in minutes.`

                }
                ]
            },
            {
                title: 'The Future',
                subheading: `Running more events with Premagic`,
                info1: `The JSConf team has discovered a valuable partner in Premagic. This software significantly facilitates the achievement of our event objectives, and collaborating with the Premagic team has been truly exceptional.`,
                testimonial: {
                    img: <StaticImage src="../../images/case-study/jsconf/avatar.png" alt="" />,
                    message: `“ For us, Premagic is undeniably a crucial tool. “`,
                    name: 'Sahil Mhapsekar',
                    info: `Organizer,<br />JSConf India`
                }
            }
        ]
    }



export default jsconfData;