
import React from 'react';
import Layout from '../../../components/Layout';
import Header from '../../../components/Header';
import CaseStudy, { CASE_STUDY_TYPES } from '../../../components/case-study/CaseStudy';
import ThumbnailImage from '../../../images/thumbnails/jsconf.png';

export function Index() {
  return (
    <Layout 
    title="Learn how Premagic optimized the attendee experience at JSConf India"
    description="Learn how Premagic optimized the attendee experience at JSConf India"
    metaImage={ThumbnailImage}>
      <Header />
      <CaseStudy type={CASE_STUDY_TYPES.JSCONF} />
    </Layout>
  );
}

export default Index;