import { Link } from 'gatsby';
import React from 'react';

import Layout from '../components/Layout';
import { StaticImage } from 'gatsby-plugin-image';
import { btn, btnPrimary } from '../ds/button.module.css';

export default function NotFoundPage() {
  return (
    <Layout title="404: Not found">
      <div className="pm-container">
        <div className="py-10">
          <Link to="/">
            <StaticImage
              src="../images/logo/premagic-logo@2xl.svg"
              alt="Premagic"
              width={150}
              placeholder="blurred"
            />
          </Link>
        </div>
        <div className="py-28">
          <h1 className="text-center my-10 text-4xl">Look like you're lost</h1>
          <p className="text-center">
            We couldn't find what you are looking for
          </p>
          <p className="text-center mt-10">
            <Link to="/" className={`${btn} ${btnPrimary}`}>
              Back home
            </Link>
          </p>
        </div>
      </div>
    </Layout>
  );
}
