import { get } from './FetchUtils';

function getCloudflareJSON(data): Promise<{
  fl: string;
  h: string;
  ip: string;
  ts: number;
  visit_scheme: string;
  uag: string;
  colo: string;
  http: string;
  loc: string;
  tls: string;
  sni: string;
  warp: string;
  gateway: string;
}> {
  return fetch('https://1.1.1.1/cdn-cgi/trace')
    .then((res) => res.text())
    .then((data) => {
      const arr = data
        .trim()
        .split('\n')
        .map((e) => e.split('='));
      return Object.fromEntries(arr);
    });
}

const URL = 'https://p.premagic.com/l/';

export type locationType = {
  ip: string;
  city: string;
  region: string;
  country: string;
  loc: object;
  postal: string;
  timezone: string;
};

let LOCATION: locationType;
export function fetchUserLocation(): Promise<locationType> {
  return get(URL).then((data) => {
    LOCATION = {
      ip: data.traits.ip_address,
      city: (data.city && data.city.names.en) || null,
      region: (data.subdivisions && data.subdivisions[0].iso_code) || null,
      country: data.country.iso_code,
      loc: {
        latitude: (data.location && data.location.latitude) || null,
        longitude: (data.location && data.location.longitude) || null,
        accuracy_radius:
          (data.location && data.location.accuracy_radius) || null,
      },
      postal: (data.postal && data.postal.code) || null,
      timezone: (data.location && data.location.time_zone) || null,
    };
    return LOCATION;
  });
}

export function getUserLocation(): locationType {
  return LOCATION || {};
}
