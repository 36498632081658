
import React from 'react';
import Layout from '../../components/Layout';
import Header from '../../components/Header';
import CaseStudy, { CASE_STUDY_TYPES } from '../../components/case-study/CaseStudy';
import ThumbnailImage from '../../images/thumbnails/case_study_home.png';
import Hero from '../../components/case-study/home/Hero'
import MagicWithUs from '../../components/inner-page/MagicWithUs';
import CasestudyLists from '../../components/case-study/home/CasestudyLists';
import MoreBrands from '../../components/case-study/home/MoreBrands';
import MoreCaseStudy from '../../components/case-study/MoreCaseStudy';

export function Index() {
  return (
    <Layout 
    title="Our customer’s success is our
    success. We value them."
    description="The future of events is a story we’re writing with our customers"
    metaImage={ThumbnailImage}>
      <Header />
      <Hero />
      <CasestudyLists />
      <MoreBrands />
      <MoreCaseStudy itemsToShow={6} currentPage='home' />
      <MagicWithUs />
    </Layout>
  );
}

export default Index;