import { Link } from 'gatsby';
import CountUp from 'react-countup';
import { BlackArrowRight, WhiteArrowRight } from "../../ds/Shapes";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { btn, btnPrimary } from '../../ds/button.module.css';
import UmagineData from './umagineData';
import SaasboomiData from './saasboomiData';
import NasscomData from './nasscomData';
import WowAwardsData from './wowAwardsData';
import ShowsOfIndiaData from './showsOfIndiaData';
import AsiaBerlinData from './asiaBerlinData';
import RedSummitData from './redSummitData';
import MercedesBenzData from './mercedesBenzData';
import JsconfData from './jsconfData';
import AioData from './aioData';
import QuoteIcon from '../../images/quote.svg';
import MagicWithUs from "../inner-page/MagicWithUs";
import MoreCaseStudy from './MoreCaseStudy';
import { useEffect, useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import UGC from './ugc/saasboomi';
import TDPData from './tdpData';
import GtechData from './gtechData';

export enum CASE_STUDY_TYPES {
  UMAGINE = 'umagine',
  SAASBOOMI = 'saasboomi',
  NASSCOM = 'nasscom',
  WOWAWARDS = 'wowawards',
  SHOWSOFINDIA = 'showsofindia',
  ASIABERLIN = 'asiaberlin',
  AIO = 'aio',
  REDSUMMIT = 'redsummit',
  MERCEDES_BENZ = 'mercedesbenz',
  JSCONF = 'jsconf',
  TDP = 'tdp',
  GTECH = 'gtech'
}

function BlueTitle(props) {
  const { text } = props;
  return (
    <h2 className="font-semibold text-4xl mb-10" style={{ color: '#7251F4' }}>{text}</h2>
  )
}

function Subheading(props) {
  const { text } = props;
  return (
    <h3 className="text-2xl font-medium text-black mb-12">{text}</h3>
  )
}

function Info(props) {
  const { text } = props;
  if (text.length === 0) return null;
  return (
    <p className="text-lg font-normal text-black mb-16" dangerouslySetInnerHTML={{ __html: text }} />
  )
}

function Testimonial(props) {
  const {data} = props;
  if(!data) return null;
  return (
    <div className="border-y border-gray-300 text-center px-10 pt-3 pb-10">
      <img className="my-8 mx-auto" src={QuoteIcon} alt="" />
      <p className="my-8 text-xl font-medium leading-relaxed">{data?.message}</p>
      <div className="flex w-max mx-auto py-5 px-6 rounded-2xl" style={{ backgroundColor: '#F5F2FF' }}>
        <div className="w-14 overflow-hidden rounded-full">{data?.img}</div>
        <div className="ml-5 flex flex-col justify-center">
          <h5 className="font-bold text-sm text-black text-left">{data?.name}</h5>
          <p className="text-xs font-normal text-black text-left" dangerouslySetInnerHTML={{ __html: data?.info }}></p>
        </div>
      </div>
    </div>
  )
}


type CaseStudyProps = {
  type: CASE_STUDY_TYPES;
}

export default function CaseStudy(props: CaseStudyProps) {
  const { type } = props;
  const { width } = useWindowDimensions();
  const isMobileUp = width >= 1080;
  const containerWidth = isMobileUp ? '1080px' : '100%';
  const heroBgHeight = isMobileUp ? '110vh' : '115vh';

  let caseStudyData: any = {};
  switch(type){

    case CASE_STUDY_TYPES.UMAGINE:
      caseStudyData = UmagineData;
      break;
    case CASE_STUDY_TYPES.SAASBOOMI:
      caseStudyData = SaasboomiData;
      break;
    case CASE_STUDY_TYPES.NASSCOM:
      caseStudyData = NasscomData;
      break;
    case CASE_STUDY_TYPES.WOWAWARDS:
      caseStudyData = WowAwardsData;
      break;
    case CASE_STUDY_TYPES.SHOWSOFINDIA:
      caseStudyData = ShowsOfIndiaData;
      break;
    case CASE_STUDY_TYPES.ASIABERLIN:
      caseStudyData = AsiaBerlinData;
      break;
    case CASE_STUDY_TYPES.AIO:
      caseStudyData = AioData;
      break;
    case CASE_STUDY_TYPES.REDSUMMIT:
      caseStudyData = RedSummitData;
      break;
    case CASE_STUDY_TYPES.MERCEDES_BENZ:
      caseStudyData = MercedesBenzData;
      break;
    case CASE_STUDY_TYPES.JSCONF:
      caseStudyData = JsconfData;
      break;
    case CASE_STUDY_TYPES.TDP:
      caseStudyData = TDPData;
      break;
    case CASE_STUDY_TYPES.GTECH:
      caseStudyData = GtechData;
      break;
    default: 
      break;  

  }

  return (
    <div className=''>
      <div className='absolute left-0 right-0 top-0 z-10' style={{ height: heroBgHeight, backgroundColor: caseStudyData?.color }}></div>

    <section className="pt-10 relative z-20">
      <div className="px-5 md:px-0 mx-auto" style={{ width: containerWidth }}>

        <div className="md:flex">
          <div className="md:w-1/2 flex flex-col justify-center text-left">
            <Link to="https://premagic.com/case-study/" className={`${btn} text-xs mt-5 relative group w-max -ml-4`}>
              <BlackArrowRight className='rotate-180 transition duration-300 ease-in-out group-hover:-translate-x-2' />
              <span className='ml-2 text-black'>Back to Case Studies</span>
            </Link>
            <h1 className="text-3xl md:text-5xl font-bold md:leading-snug mb-12 text-black" dangerouslySetInnerHTML={{ __html: caseStudyData?.heroTitle }}></h1>
            <Link to="/request-a-demo/" className={`${btn} ${btnPrimary} text-xs md:mt-5 mb-20 md:mb-0 relative w-max group`}>
              <span className='mr-4 ml-2'>Talk to our expert</span>
              <WhiteArrowRight className='transition duration-300 ease-in-out group-hover:translate-x-2 mr-2' />
            </Link>
          </div>
          <div className="md:w-1/2 -ml-12 md:ml-0 flex items-center">
            {caseStudyData?.heroImage}
          </div>
        </div>

        <div className="rounded-2xl md:shadow-xl md:p-10 md:p-20 bg-white mt-20">
          <div className="flex justify-between flex-wrap md:flex-nowrap">
            {
              caseStudyData?.metrics.values.map((item,index) => (
                <div className="mb-10 md:mb-0 text-center md:text-left w-1/2 md:w-auto">
                  <h4 className="font-medium text-4xl text-black">
                    <CountUp end={Number(item.value.replaceAll(',', ''))}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start}>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                    </CountUp>
                  </h4>
                  <p className="text-sm font-normal text-black mt-2">{item.label}</p>
                </div>
              ))
            }
          </div> 

          <div className="mt-10 md:mt-24 md:flex">
            <div className="md:w-7/12">
              <h2 className="text-black text-4xl font-semibold">{caseStudyData?.metrics?.title}</h2>  
              <p className="mt-10 leading-relaxed text-black">{caseStudyData?.metrics?.content}</p>
              <p className="text-xl text-black mt-10 font-medium">{caseStudyData?.metrics?.footer}</p>
            </div>
            <div className="md:w-5/12 md:px-10 mt-10 md:mt-0">
              <div className="rounded-2xl bg-purple-100 p-10">
                <h4 className="text-lg text-black font-semibold">Host</h4>
                <p className="text-sm text-black font-normal mt-1">{caseStudyData?.metrics.host}</p>
                <h4 className="text-lg text-black font-semibold mt-10">Organizer</h4>
                <p className="text-sm text-black font-normal mt-1">{caseStudyData?.metrics.organizer}</p>
                <h4 className="text-lg text-black font-semibold mt-10">Industry</h4>
                <p className="text-sm text-black font-normal mt-1">{caseStudyData?.metrics.industry}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-20 md:mt-40 mb-28">
          {caseStudyData?.images[0]?.img}
        </div>
        {
          caseStudyData.caseStudyData.map(item => (
            <div>
              <div className="md:px-44 md:mb-40">
                <BlueTitle text={item.title} />
                <Subheading text={item.subheading} />
                <Info text={item.info1} />
                <Testimonial data={item?.testimonial || null} />
                <div className="mt-10" />
                <Info text={item?.info2 || ''} />
                {
                  item.gridViewData && 
                  <div className="flex flex-wrap">
                    {
                      item.gridViewData.map(data => (
                        <div className="w-full md:w-1/2 mb-20 md:mb-10 px-10">
                          <div className="flex items-center">
                            {data.icon}
                            <h4 className="ml-3 text-lg font-medium">{data.title}</h4>
                          </div>
                          <p className="mt-10 text-sm">{data.description}</p>
                        </div>
                      ))
                    }

                  </div>
                }
              </div>
              {
                item.title === 'The Solution' &&
                <div className="mb-28">
                  {caseStudyData?.images[1]?.img}
                </div>
              }
            </div>
          ))
        }
        <div className="my-20">
          <h2 className="text-black text-center mb-16 text-4xl md:text-5xl font-bold">Sneak peek of the event</h2>
          {caseStudyData.gallery}
        </div>
      </div>
      {type === CASE_STUDY_TYPES.SAASBOOMI && <UGC />}
      <MoreCaseStudy currentPage={type} itemsToShow={3} />
      <MagicWithUs />
    </section>
    </div>
  );
}