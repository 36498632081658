
import { StaticImage } from 'gatsby-plugin-image';
import Flickity from 'react-flickity-component';
import { Link } from 'gatsby';
import useWindowDimensions from '../../utils/useWindowDimensions';
import { BlackArrowRight, QuoteIconWithColor } from '../../ds/Shapes';
import {
  btnSmall,
  btnPrimary
} from '../../ds/button.module.css';
import { File } from '../../ds/Shapes';

let target;

export const TESTIMONIALS: Array<{
    logo: React.ReactNode;
    message: string;
    profile: React.ReactNode;
    name: string;
    designation: string;
    company: string;
    color: string;
    lightColor: string;
    lightestColor: string;
    url: string;
    stats: string;
 }> = [
    {
        logo: <StaticImage src='../../images/case-study/mercedesbenz/logo.png' height={60} alt="" />,
        message: `"Premagic has surpassed our expectations in every way. Quick delivery in a wonderful package. That's exactly what we had been looking for.”`,
        profile: <StaticImage src='../../images/case-study/mercedesbenz/avatar.png' class="rounded-full" width={60} height={60} alt="asd" />,
        name: 'Thomas Alex',
        designation: 'Managing Director',
        company: 'Mercedes-Benz Coastal Star',
        color: '#1F0F61',
        lightColor: '#DBD2FD',
        lightestColor: '#F5F2FF',
        url: '/case-study/mercedes-benz/',
        stats: `<span class='font-bold text-6xl'>1500</span><br /> <br />Photos delivered to attendees in real-time at the Mercedes Benz EQR-SUV showcase.`
    },
    {
        logo: <StaticImage src='../../images/case-study/asiaberlin/logo.png' height={30} alt="" />,
        message: `"Premagic really supported us during the AsiaBerlin summit. We saw great value in the partnership, and the responsiveness of the Premagic team was a big part of that.”`,
        profile: <StaticImage src='../../images/case-study/asiaberlin/avatar.png' class="rounded-full" width={60} height={60} alt="asd" />,
        name: 'Swantje Gies',
        designation: 'Managing Director',
        company: 'AsiaBerlin Forum',
        color: '#613F00',
        lightColor: '#FFE7C0',
        lightestColor: '#FFF7E8',
        url: '/case-study/asiaberlin/',
        stats: `<span class='font-bold text-6xl'>130k</span><br /> <br />Impressions were generated at the Asia Berlin Summit.`
    },
    {
        logo: <StaticImage src='../../images/case-study/wowawards/logo.png' height={30} alt="" />,
        message: `"The whole idea behind WOW events is to, well, wow our guests with the coolest tech and event innovations out there.”`,
        profile: <StaticImage src='../../images/case-study/wowawards/avatar.png' class="rounded-full" width={60} height={60} alt="asd" />,
        name: 'Deepak Choudhary',
        designation: 'Founder & MD-EVA Live',
        company: 'EVENTFAQS Media, WOW Awards',
        color: '#109A6D',
        lightColor: '#CEFCED',
        lightestColor: '#EAFFF8',
        url: '/case-study/wowawards/',
        stats: `<span class='font-bold text-6xl'>50%</span><br /> <br />Growth in the new pipeline was achieved from the WOW Awards compared to 2022.`
    },
    {
        logo: <StaticImage src='../../images/case-study/saasboomi/logo.png' height={30} alt="" />,
        message: `"Premagic really came through with solutions that not only saved us substantial time and effort but also felt like having an extra member on our team.”`,
        profile: <StaticImage src='../../images/case-study/saasboomi/avatar.png' class="rounded-full" width={60} height={60} alt="asd" />,
        name: 'Malavika Velayanikal',
        designation: 'Director of Initiatives & Events',
        company: 'SaaSBOOMi',
        color: '#E15D4B',
        lightColor: '#FFD5CF',
        lightestColor: '#e5052117',
        url: '/case-study/saasboomi/',
        stats: `<span class='font-bold text-6xl'>200+</span><br /> <br />Social media posts were created about SaaSBOOMi.`
    },
    {
        logo: <StaticImage src='../../images/case-study/umagine/logo.png' height={30} alt="" />,
        message: `"Finding effective ways to showcase our partners and sponsors is always a challenge. I found Premagic’s solution to bring value to these brands truly ingenious." `,
        profile: <StaticImage src='../../images/case-study/umagine/avatar.png' class="rounded-full" width={60} height={60} alt="asd" />,
        name: 'Kazhal Vendhan',
        designation: 'Manager',
        company: 'CII',
        color: '#324100',
        lightColor: '#e6f6b0',
        lightestColor: '#F3FFD0',
        url: '/case-study/umagine/',
        stats: `<span class='font-bold text-6xl'>40k</span><br /> <br />Impressions were made at Umagine Chennai.`
    },
]


function TestimonialsCard(props) {
  const { data } = props;

  return (
    <div className='flex flex-wrap mx-5 outline-none'>
      <div className="px-7 md:px-7 w-full md:w-2/3 rounded-tl-2xl rounded-tr-2xl md:rounded-tr-none md:rounded-bl-2xl" style={{ backgroundColor: data.lightestColor }}>
        <div className="p-8 md:px-16 w-full h-full text-center rounded-2xl flex flex-col">
          <div className='-mt-10 ml-5 md:ml-24 scale-150'>
            <QuoteIconWithColor color={data.color} />
          </div>
          <div className='my-5 h-16 flex items-center justify-center'>
            {data?.logo}
          </div>
          <p className={`py-8 -mx-10 md:mx-0 overflow-hidden text-lg leading-relaxed md:mb-10 md:h-48`}>{data?.message} </p>
          <div className='flex justify-center'>
            <div className='bg-white w-fit -mx-5 md:mx-0 p-5 rounded-xl flex mt-auto shadow-lg'>
              <div className='rounded-full mr-5'>
                {data.profile}
              </div>
              <div>
                <h4 className="text-sm font-extrabold text-black text-left">{data?.name}</h4>
                <p className="text-sm text-left">{data?.designation || ''}</p>
                <p className='text-sm text-left'>{data?.company || ''}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full md:w-1/3 p-10 text-center md:text-left rounded-br-2xl rounded-bl-2xl md:rounded-bl-none md:rounded-tr-2xl' style={{ backgroundColor: data.lightColor }}>
        <div dangerouslySetInnerHTML={{ __html: data.stats }} className='text-xl font-semibold' style={{ color: data.color }}></div>
        <Link to={data.url} className={`${btnSmall} ${btnPrimary} text-xs relative w-max mt-5`}>
          <File className='mr-2' />
          <span>Read Full Case Study</span>
        </Link>
      </div>
    </div>
  );
}

type Props = {
  hasHeading: boolean;
}

export function Feebacks(props) {
  const { hasHeading } = props;
  const { width } = useWindowDimensions();
  const isMobileUp = width >= 1080;
  const containerWidth = isMobileUp ? '1080px' : '100%';


  const flickityOptions = {
    // autoPlay: 2000,
    wrapAround: true,
    // selectedAttraction: 0.01,
    // friction: 0.2,
    // cellAlign: 'center',
    // contain: true,
    // pageDots: true,
    // prevNextButtons: true,
    // pauseAutoPlayOnHover: false
  };

    return (
      <section>
        <div style={{ width: containerWidth }} className='mx-auto mb-16'>
          {
            hasHeading &&
            <h2 className='text-black text-3xl md:text-5xl font-bold text-center leading-tight md:leading-tight relative z-20 py-20'>Hear it from our Customers</h2>
          }
        <Flickity
              flickityRef={(c) => (target = c)}
              className="carousel outline-none" // default ''
              // elementType="div"
              options={flickityOptions} // takes flickity options {}
              // disableImagesLoaded={false} // default false
              reloadOnUpdate // default false
              // static // default false
            >
              {
                TESTIMONIALS.map(item => (
                  <TestimonialsCard data={item} />
                ))
              }
        </Flickity>
        <div className='flex items-center justify-center mx-auto mt-10 '>
          <button className='rotate-180 border rounded-full border-purple-200 p-5 mx-3 group hover:bg-purple-300 transition duration-200 ease-in-out' onClick={() => target.previous()}>
            <BlackArrowRight className='transition duration-200 ease-in-out group-active:translate-x-1' />
          </button>
          <button className='border rounded-full border-purple-200 p-5 mx-3 group hover:bg-purple-300 transition duration-200 ease-in-out' onClick={() => target.next()}>
            <BlackArrowRight className='transition duration-200 ease-in-out group-active:translate-x-1' />
          </button>
        </div>
        </div>
      </section>
    );
  }
  
  export default Feebacks;
