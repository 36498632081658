import React, { useState } from 'react';
import Lottie from "lottie-react";
import {
  btn,
  btnSmall,
  btnPrimary
} from '../../ds/button.module.css';
import { WhiteArrowRight } from '../../ds/Shapes';
import { UploadIcon } from '../../ds/SvgIcons';
import RocketAnimation from '../../lottie-files/rocket.json';

type Props = {
  show: boolean;
}
export function Success(props: Props) {

  const { show } = props; 

  if(!show) return null;


  return (
    <div className='border shadow-lg p-10 rounded-2xl border-slate-100 relative' style={{ backgroundColor: '#7251F4' }}>
      <div className='absolute left-1/2 -translate-x-1/2 -top-20'>
        <Lottie animationData={RocketAnimation} loop={false} style={{ width: '180px' }} />
      </div>
      <h4 className='font-semibold text-white mt-10'>Good news!</h4>
      <p className='text-sm text-white opacity-70 mt-2 text-sm md:pr-20'>Your photos are being processed and your customized Premagic gallery will reach you in no time.</p>
      <h4 className='text-white font-bold mt-6'>Hang in there!</h4>
    </div>
  )
}