import { Link } from "gatsby";
import { btn, btnPrimary } from '../ds/button.module.css';
import { WhiteArrowRight } from "../ds/Shapes";
import { CheckIconGreen } from '../ds/SvgIcons';
import { StaticImage } from "gatsby-plugin-image";
import Header from "../components/Header";
import Layout from "../components/Layout";
import HowItWorks from '../components/registration-page/HowItWorks';
import CustomizableLandingPage from '../components/registration-page/CustomizableLandingPage';

export default function index() {
    return (
        <Layout title="Simplify your event registrations" description="Planning an event? Build stunning, branded registration pages that can:Capture registrations, Approve or decline attendees, Embed on your website, Sync event data via APIs or CSV uploads, Swift, secure check-ins with face recognition">
    <div className=''>
        <div className="" style={{ backgroundColor: '#7251F4' }}>
            <Header isWhiteLogo />
        </div>

        <section className="py-10" style={{ backgroundColor: '#7251F4' }}>
        <div className="px-5 md:px-0 mx-auto md:w-[1080px]">
            <div className="md:flex items-center h-[850px] md:h-[600px]">
                <div className="md:w-5/12 flex flex-col justify-center text-left">
                    <h1 className="text-3xl md:text-5xl font-bold mb-4 text-white"><span style={{ color: '#61F2C2' }}>Simplify</span> your <br />event registrations</h1>
                    <p className="text-white mb-5">Planning an event? Build stunning, branded <br />registration pages that can: </p>
                    <div className="flex my-2">
                        <CheckIconGreen />
                        <p className="text-white ml-3">Capture registrations</p>
                    </div>
                    <div className="flex my-2">
                        <CheckIconGreen />
                        <p className="text-white ml-3">Approve or decline attendees</p>
                    </div>
                    <div className="flex my-2">
                        <CheckIconGreen />
                        <p className="text-white ml-3">Embed on your website</p>
                    </div>
                    <div className="flex my-2">
                        <CheckIconGreen />
                        <p className="text-white ml-3">Sync event data via APIs or CSV uploads</p>
                    </div>
                    <div className="flex my-2">
                        <CheckIconGreen />
                        <p className="text-white ml-3">Swift, secure check-ins with face recognition</p>
                    </div>
                    <Link to="/request-a-demo/" className={`${btn} ${btnPrimary} text-xs mt-5 mb-10 md:mb-0 relative w-max group`}>
                    <span className='mr-4 ml-2'>Show me how</span>
                    <WhiteArrowRight className='transition duration-300 ease-in-out group-hover:translate-x-2 mr-2' />
                    </Link>
                </div>
                <div className="md:w-7/12 md:ml-0 relative">
                    <div className="">
                        <StaticImage src="../images/reg_module_hero.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
        </section>  
        <section className="md:pt-10">
        <div className="px-5 md:px-0 mx-auto md:w-[1080px]">
            <div className="flex flex-col md:flex-row flex-col-reverse">
                <div className="md:w-1/2 flex flex-col justify-center text-left md:pr-10 md:mt-0">
                    <h4 className="font-bold text-2xl mb-5" style={{ color: '#7251F4' }}>How it works</h4>
                    <h1 className="text-3xl md:text-4xl font-bold md:leading-snug mb-4" style={{ color:'#7251F4' }}>A seamless registration <br />experience for attendees</h1>
                    <p>Power unforgettable events with the most 
                    convenient and efficient online registration system.</p>
                    <Link to="/request-a-demo/" className={`${btn} ${btnPrimary} text-xs mt-10 mb-10 md:mb-0 relative w-max group`}>
                    <span className='mr-4 ml-2'>Show me how</span>
                    <WhiteArrowRight className='transition duration-300 ease-in-out group-hover:translate-x-2 mr-2' />
                    </Link>
                </div>
                <div className="md:w-1/2 md:pl-10 text-right mt-10 md:mt-0 -mr-10 md:mr-0">
                    <StaticImage src="../images/reg_how_it_works.png" alt="" />
                </div>
            </div>
        </div>
        </section>
       
        <div className="hidden md:block">
            <HowItWorks />
        </div>
        <CustomizableLandingPage />

        <section className='py-20 md:py-0' style={{ backgroundColor: '#7251F4' }}>
        <div className="px-5 md:px-0 mx-auto md:flex py-20 items-center md:w-[1080px]">
          <div className='md:w-1/2 flex flex-col justify-center relative mb-20 md:mb-0'>
            <div className="md:absolute">
                <StaticImage width={350} src="../images/etech_event.png" alt="" />
            </div>
            
          </div>
          <div className='md:w-1/2'>
            <h4 className="text-white text-center text-2xl"><span style={{ color: '#61F2C2' }}>“</span>Registration has never been smoother! Quick setup, engaging forms, and face-recognition check-ins made our event a breeze. 
            Absolutely fantastic!<span style={{ color: '#61F2C2' }}>”</span></h4>
            <h5 className="text-2xl text-center mt-10" style={{ color: '#61F2C2' }}>Shaili Chheda</h5>
            <p className="text-white text-xl text-center">Client Services, Laqshya Live Experiences</p>
          </div>
        </div>
        </section>
        
       
        <section className='my-20 mt-40'>
        <div className="px-5 md:px-0 mx-auto flex py-10 md:items-center flex-wrap md:w-[1080px]">
            <div className="w-1/2 mb-20 md:mb-0 md:w-1/4 text-center">
                <StaticImage class='w-20 md:w-24' src="../images/perfomance_ico_1.png" alt="" />
                <h4 style={{ color: '#FF6550' }} className="text-4xl font-bold my-5">100%</h4>
                <p>more feature-rich than<br />
                Google or Zoho forms</p>
            </div>
            <div className="w-1/2 mb-20 md:mb-0 md:w-1/4 text-center">
                <StaticImage class='w-20 md:w-20' src="../images/perfomance_ico_2.png" alt="" />
                <h4 style={{ color: '#FF6550' }} className="text-4xl font-bold my-8 md:my-5 ">100%</h4>
                <p>faster attendee<br />
                approval process</p>
            </div>
            <div className="w-1/2 md:w-1/4 text-center">
                <StaticImage class='w-20 md:w-20' src="../images/perfomance_ico_3.png" alt="" />
                <h4 style={{ color: '#FF6550' }} className="text-4xl font-bold my-5">99%</h4>
                <p>more secure than traditional<br />
                check-in methods</p>
            </div>
            <div className="w-1/2 md:w-1/4 text-center">
                <StaticImage class='w-24 md:w-24' src="../images/perfomance_ico_4.png" alt="" />
                <h4 style={{ color: '#FF6550' }} className="text-4xl font-bold my-2 md:my-5">5000+</h4>
                <p>events powered<br />
                globally</p>
            </div>
        </div>
        </section>
       


        {/* <RequestDemo /> */}
       
    </div>      
    </Layout>
    )
}