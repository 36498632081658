
import React from 'react';
import Layout from '../../../components/Layout';
import Header from '../../../components/Header';
import CaseStudy, { CASE_STUDY_TYPES } from '../../../components/case-study/CaseStudy';
import ThumbnailImage from '../../../images/thumbnails/redsummit.png';

export function Index() {
  return (
    <Layout 
    title="Explore the Premagic upgrade that made the RED Summit unforgettable"
    description="Explore the Premagic upgrade that made the RED Summit unforgettable"
    metaImage={ThumbnailImage}>
      <Header />
      <CaseStudy type={CASE_STUDY_TYPES.REDSUMMIT} />
    </Layout>
  );
}

export default Index;